import React from "react";
import Button from "../Button";
import { CalendarIcon, ClockIcon, PersonIcon } from "@radix-ui/react-icons";
import { LocationIcon } from "../../assets/svg/LocationIcon";
import { Controller, useForm } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DatePickerInputComponent,
  TimePickerInputComponent,
} from "../Input/CustomInputComponent";
import { APP_ROUTES } from "../../utils/app-routes";
import FormSelect from "../Select/FormSelect";
import { priorityData } from "../CreateTask/constant";
import { TimePicker } from "antd";
const weeklyOptions = [
  { label: "Every week", value: "weekly" },
  { label: "Every 2 weeks", value: "bi-weekly" },
  { label: "Every month", value: "monthly" },
];

export const CreateEventForm = ({ setIsCreateModalOpen, onSubmit }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    reset,
    setValue,
  } = useForm({
    defaultValues: {},
    // resolver: yupResolver(),
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="max-h-[500px] overflow-y-auto">
        <form className="p-24 w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <span className="text-gray-300 text-sm mb-2 block">Title</span>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <input
                  className="w-full p-3 quick-add-task-container create-project-input"
                  style={{ background: "var(--App-Background)" }}
                  placeholder="Event Title"
                  required
                  {...register("title")}
                />
              )}
            />
          </div>

          <div>
            <span className="text-gray-300 text-sm mb-2 block">
              Description
            </span>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <textarea
                  className="w-full quick-add-task-container create-project-input"
                  style={{
                    background: "var(--App-Background)",
                    minHeight: "100px",
                    maxHeight: "200px",
                    padding: "12px",
                  }}
                  placeholder="Event Description"
                  {...register("eventDetails")}
                />
              )}
            />
          </div>

          <div className="flex justify-between gap-4 mb-4">
            <div className="w-1/2">
              <Controller
                name={`startDate`}
                control={control}
                render={({ field }) => (
                  // <ReactDatePicker
                  //   selected={field.value}
                  //   fixedHeight
                  //   onChange={(date) => field.onChange(date)}
                  //   className="m-t-8 w-40 quick-add-task-container create-project-input"
                  //   placeholderText="Due Date"
                  //   dateFormat={"dd/MM/yyyy"}
                  // />
                  <div>
                    <DatePickerInputComponent
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      icon={<CalendarIcon color="#fff" />}
                      placeholderText="Start Date"
                    />
                  </div>
                )}
              />
            </div>
            <div className="w-1/2">
              <Controller
                name={`endDate`}
                control={control}
                render={({ field }) => (
                  <div>
                    <DatePickerInputComponent
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      icon={<CalendarIcon color="#fff" />}
                      placeholderText="End Date"
                      // {...register("endDate")}
                    />
                  </div>
                )}
              />
            </div>
          </div>

          <div className="mb-4">
            <Controller
              name={`time`}
              control={control}
              render={({ field }) => (
                <div>
                  <TimePicker.RangePicker
                    className="w-full quick-add-task-container create-project-input"
                    onChange={(date) => field.onChange(date)}
                    placeholder={["Start Time", "End Time"]}
                    style={{
                      background: "var(--App-Background)",
                    }}
                    // {...register("startTime")}
                  />
                </div>
              )}
            />
          </div>

          {/* <div className="flex-row align-center w-90 m-t-12">
            <div className="flex-row justify-space-between align-center">
              <label className="left white align-center">
                <Controller
                  name="rememberMe"
                  control={control}
                  render={({ field }) => <input type="checkbox" {...field} />}
                />
                <span className="regularS">Recurring Event</span>
              </label>
            </div>
            <div className="w-30 m-l-8">
              <FormSelect
                className="m-t-6"
                control={control}
                label="weekly"
                id="weekly"
                options={weeklyOptions}
                // onChange={(e) => setValue("weekly", e.value)}
                // value={"Low"}
                name="weekly"
                placeholder="Every week"
              />
            </div>
          </div> */}

          <div className="mb-4">
            <span className="text-gray-300 text-sm mb-2 block">Location</span>
            <Controller
              name="location"
              control={control}
              render={({ field }) => (
                <input
                  className="w-full p-3 quick-add-task-container create-project-input"
                  style={{ background: "var(--App-Background)" }}
                  placeholder="Location"
                  {...register("location")}
                />
              )}
            />
          </div>

          <div className="flex justify-end">
            <Button
              variety="outlined"
              onClick={() => setIsCreateModalOpen(false)}
              className="mr-2"
            >
              Cancel
            </Button>
            <Button variety="filled" type="submit">
              Create Event
            </Button>
          </div>
        </form>
      </div>
    </LocalizationProvider>
  );
};
