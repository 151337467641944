import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AddPreIcon } from "../../assets/svg/AddPreIcon";
import { SearchOutlined } from "../../assets/svg/SearchOutliined";
import { EventViewType } from "../../constants/constant";
import { useDataQuery } from "../../hooks/crud/data.query";
import { useMutationCreate } from "../../hooks/crud/mutation.create";
import { useMutationDelete } from "../../hooks/crud/mutation.delete";
import { API_END_POINTS } from "../../utils/api-endpoint";
import Button from "../Button";
import Calendar from "../Calendar/Calendar";
import Card from "../Card/Card";
import Dialog from "../Dialog/Dialog";
import { EventListViewComponent } from "../ListView/ListViewComponent";
import Modal, { CreateModal } from "../Modal/Modal";
import { EventModalViewDetails } from "./ModalView";

import { CreateEventForm } from "../CreateEvent/CreateEvent";
import GoogleCalendar from "../GoogleCalendar/GoogleCalendar";
import { notify } from "../Toast/ToastComponent";

const CalendarComponent = ({
  className,
  isEventTab,
  timelineData,
  isLoadingTimeline,
  googleCalendarStatus,
  ...props
}) => {
  console.log(googleCalendarStatus);
  const [selectedViewType, setSelectedViewType] = useState("Calendar View");
  const [data, setData] = useState();
  const [selectedData, setSelectedData] = useState();
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [googleEvents, setGoogleEvents] = useState([]);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    reset,
  } = useForm({});

  const {
    data: dashboardData,
    refetch: refetchDashboardData,
    error: dashboardError,
  } = useDataQuery({
    url: API_END_POINTS.dashboard,
    params: {},
  });

  //create a event
  const { mutate: createEvent } = useMutationCreate(API_END_POINTS.events);

  // delete the event
  const { mutate: deleteEvent } = useMutationDelete(
    API_END_POINTS.events,
    selectedData?._id
  );

  const handleEventClick = (e) => {
    setIsEventModalOpen(true);
    setModalTitle("Event Details");
    setSelectedData(e);
  };

  const handleCreate = () => {
    setIsCreateModalOpen(true);
    setModalTitle("Create Event");
    setSelectedData({});
  };

  const handleDeleteEvent = () => {
    setConfirmationDialog(true);
  };

  const handleDeleteConfirmation = () => {
    setIsButtonLoading(true);
    deleteEvent(
      {},
      {
        onSuccess(res) {
          setIsEventModalOpen(false);
          refetchDashboardData();
          setConfirmationDialog(false);
          setIsButtonLoading(false);
          notify("Event deleted successfully", "success");
        },
      }
    );
  };

  const onSubmit = (data) => {
    console.log("Data before", {
      data,
    });
    const startTime = data?.time?.map((item) =>
      new Date(item)?.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
    )?.[0];
    const endTime = data?.time?.map((item) =>
      new Date(item)?.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
    )?.[1];
    const startDateTime = new Date(
      moment(data?.startDate).format("YYYY-MM-DD") + " " + startTime
    );
    const endDateTime = new Date(
      moment(data?.endDate).format("YYYY-MM-DD") + " " + endTime
    );
    console.log("Data", {
      ...data,
      startDateTime: startDateTime.toISOString(),
      endDateTime: endDateTime.toISOString(),
      startTime: startTime,
      endTime: endTime,
      tags: [],
      eventType: "Meeting",
      collaboration: "Public",
    });
    createEvent(
      {
        ...data,
        startDateTime: startDateTime.toISOString(),
        endDateTime: endDateTime.toISOString(),
        startTime: startTime,
        endTime: endTime,
        tags: [],
        eventType: "Meeting",
        collaboration: "Public",
      },
      {
        onSuccess(res) {
          setIsCreateModalOpen(false);
          refetchDashboardData();
          notify("Event created successfully", "success");
          reset();
        },
      }
    );
  };

  // Transform Google events to match your event format
  const transformGoogleEvent = (googleEvent) => {
    return {
      _id: googleEvent?.id,
      title: googleEvent?.summary,
      eventDetails: googleEvent?.description || "",
      collaboration: "Public",
      tags: [],
      location:
        googleEvent?.hangoutLink || googleEvent?.location || "Google Meet",
      eventType: googleEvent?.eventType || "Meeting",
      startDate: googleEvent?.start?.dateTime?.split("T")[0],
      startTime: googleEvent?.start?.dateTime?.split("T")[1].split("+")[0],
      endDate: googleEvent?.end?.dateTime?.split("T")[0],
      endTime: googleEvent?.end?.dateTime?.split("T")[1].split("+")[0],
      user: googleEvent?.creator?.email,
      createdAt: googleEvent?.created,
      updatedAt: googleEvent?.updated,
      __v: 0,
    };
  };

  useEffect(() => {
    let data = dashboardData?.data?.events || [];
    if (googleEvents) {
      const transformedGoogleEvents = googleEvents.map(transformGoogleEvent);
      data = [...data, ...transformedGoogleEvents];
    }
    setData(data);
  }, [dashboardData, googleEvents]);

  return (
    <div className={className}>
      <div className="flex-column">
        <Card>
          <div className="flex-row justify-space-between border-b-1 items-center">
            <div className="flex-row justify-space-between w-30 p-12">
              {EventViewType.map((item) => (
                <Button
                  key={item.id}
                  onClick={() => setSelectedViewType(item.name)}
                  variety={
                    item.name === selectedViewType ? "filled" : "outlined"
                  }
                  className="mr-4"
                >
                  {item.name}
                </Button>
              ))}
            </div>

            <div className="flex-row justify-space-between m-4 items-center">
              <div className="mt-1 items-center">
                <GoogleCalendar
                  setGoogleEvents={setGoogleEvents}
                  googleCalendarStatus={googleCalendarStatus}
                />
              </div>
              <Button
                className="flex-row mediumM"
                variety="filled"
                height={"48px"}
                onClick={handleCreate}
              >
                <AddPreIcon /> Create Event
              </Button>
            </div>
          </div>

          <div className="card-second-wrapper content-container">
            <div className="flex-row justify-space-between items-center">
              <div></div>
              {/* Search functionality */}
              <div className="relative flex items-center mr-8">
                {showSearch && (
                  <div className="absolute right-0 transform translate-x-[-40px]">
                    <input
                      className="quick-add-task-container create-project-input search-input"
                      style={{
                        background: "var(--App-Background)",
                        marginRight: 8,
                        width: "250px",
                      }}
                      placeholder="Search..."
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                )}
                <div
                  className="quick-add-task-container align-center flex-row p-8 cursor-pointer z-10"
                  onClick={() => setShowSearch(!showSearch)}
                >
                  <SearchOutlined />
                </div>
              </div>
            </div>

            {/* Main content */}
            <div className="m-8">
              {selectedViewType === "List View" ? (
                data?.map((item, index) => (
                  <EventListViewComponent
                    key={item._id}
                    item={item}
                    title={item?.title}
                    date={moment(item?.startDate).format("MMM D YYYY")}
                    description={item?.eventDetails}
                    location={item?.location}
                    onClick={() => handleEventClick(item)}
                  />
                ))
              ) : (
                <Calendar data={data} deleteTask={handleDeleteEvent} />
              )}
            </div>
          </div>
        </Card>
        {/* Modals */}
        {/* Event Details Modal */}
        <Modal
          isOpen={isEventModalOpen}
          onClose={() => setIsEventModalOpen(false)}
          title={modalTitle}
        >
          <EventModalViewDetails
            data={selectedData}
            onDelete={handleDeleteEvent}
          />
        </Modal>

        {/* Create Event Modal */}
        <CreateModal
          isOpen={isCreateModalOpen}
          onClose={() => {
            setIsCreateModalOpen(false);
            reset();
          }}
          title={modalTitle}
        >
          <CreateEventForm
            onSubmit={onSubmit}
            control={control}
            errors={errors}
            register={register}
          />
        </CreateModal>

        {/* Delete Confirmation Dialog */}
        <Dialog
          isOpen={confirmationDialog}
          onClose={() => setConfirmationDialog(false)}
          title="Delete Event"
          description="Are you sure you want to delete this event? This action cannot be undone."
          confirmText="Delete"
          cancelText="Cancel"
          onConfirm={handleDeleteConfirmation}
          isLoading={isButtonLoading}
        />
      </div>
    </div>
  );
};

export default CalendarComponent;
