export const PlatformData = [
  {
    id: 1,
    platform: "Spotify",
    icon: "spotify",
    color: "#1DB954",
    description: "Music streaming service",
    link: "https://www.spotify.com/",
  },
  {
    id: 2,
    platform: "Apple Music",
    icon: "apple",
    color: "#000",
    description: "Music streaming service",
    link: "https://www.apple.com/apple-music/",
  },
  {
    id: 3,
    platform: "YouTube",
    icon: "youtube",
    color: "#FF0000",
    description: "Video sharing platform",
    link: "https://www.youtube.com/",
  },
];

export const DashboardTab = [
  { id: 1, tabName: "Tasks" },
  // { id: 2, tabName: "Events" },
  { id: 2, tabName: "Projects" },
];

export const ViewType = [
  { id: 1, name: "List View" },
  // { id: 2, name: "Calendar View" },
  { id: 3, name: "Timeline View" },
];

export const EventViewType = [
  { id: 1, name: "Calendar View" },
  { id: 2, name: "List View" },
];

export const ChartDropDown = [
  { id: 1, value: "Weekly", label: "Week" },
  { id: 2, value: "Monthly", label: "Month" },
  { id: 3, value: "Yearly", label: "Year" },
];

export const estimatedTimeData = [
  { id: 1, value: 1, label: "1 hr" },
  { id: 2, value: 2, label: "2 hrs" },
  { id: 3, value: 3, label: "3 hrs" },
  { id: 4, value: 4, label: "4 hrs" },
  { id: 5, value: 5, label: "5 hrs" },
  { id: 6, value: 6, label: "6 hrs" },
  { id: 7, value: 7, label: "7 hrs" },
  { id: 8, value: 8, label: "8 hrs" },
  { id: 9, value: 9, label: "9 hrs" },
  { id: 10, value: 10, label: "10 hrs" },
];
