import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router";
import loginImage from "../../../assets/png/login.png";
import { Logo } from "../../../assets/svg/Logo";
import { APP_ROUTES } from "../../../utils/app-routes";
import "../Signup/Signup.css";
import Button from "../../../components/Button";
import { useMutationCreate } from "../../../hooks/crud/mutation.create";
import { API_END_POINTS } from "../../../utils/api-endpoint";
import { GENESIS_USER_TOKEN, USER_DETAILS } from "../../../utils/constants";
import { notify } from "../../../components/Toast/ToastComponent";

const OtpVerification = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [otp, setOtp] = useState(Array(6).fill("")); // to hold OTP input
  const [errorMessage, setErrorMessage] = useState(""); // Add state for error message
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const { mutate: verifyOtp } = useMutationCreate(
    API_END_POINTS.otpVerification
  );

  const { mutate: verifyForgotOtp } = useMutationCreate(
    API_END_POINTS.forgotPasswordVerify
  );

  const { mutate: resendOtp } = useMutationCreate(API_END_POINTS.resendOtp);

  // Add paste handler
  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").trim();
    
    // Check if pasted content is a 6-digit number
    if (/^\d{6}$/.test(pastedData)) {
      const otpArray = pastedData.split("").map(char => char);
      setOtp(otpArray);
      
      // Automatically trigger verification after a short delay
      setTimeout(() => {
        handleSubmit(otpArray.join(""));
      }, 100);
    }
  };

  // Modified handleSubmit to optionally accept direct OTP value
  const handleSubmit = (directOtp = null) => {
    setErrorMessage("");
    setIsLoading(true); // Start loading
    const otpValue = directOtp || otp.join("");
    
    if (state?.isForgot) {
      verifyForgotOtp(
        { otp: otpValue, email: state?.email },
        {
          onSuccess(res) {
            setIsLoading(false); // Stop loading
            navigate(APP_ROUTES.resetPassword);
          },
          onError: (error) => {
            setIsLoading(false); // Stop loading
            setErrorMessage(error?.message || error?.error?.message);
            notify(error?.error?.message, "error");
          },
        }
      );
      return;
    } else {
      verifyOtp(
        { otp: otpValue, email: state?.email },
        {
          onSuccess(res) {
            setIsLoading(false); // Stop loading
            try {
              localStorage.setItem(GENESIS_USER_TOKEN, JSON.stringify(res?.token));
              const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
              if (userDetails && userDetails.data) {
                userDetails.data.isVerified = true;
                localStorage.setItem(USER_DETAILS, JSON.stringify(userDetails));
              }
              navigate(APP_ROUTES.paymentPlan);
            } catch (error) {
              console.error('Error updating user details:', error);
              navigate(APP_ROUTES.paymentPlan);
            }
          },
          onError: (error) => {
            setIsLoading(false); // Stop loading
            setErrorMessage(error?.message || error?.error?.message);
            notify(error?.error?.message, "error");
          },
        }
      );
    }
  };

  // Modified handleChange to handle auto-verification when all digits are entered
  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    const newOtp = [...otp.map((d, idx) => (idx === index ? element.value : d))];
    setOtp(newOtp);

    // Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }

    // If this is the last digit and all digits are filled, trigger verification
    if (index === 5 && element.value !== "" && newOtp.every(digit => digit !== "")) {
      setTimeout(() => {
        handleSubmit(newOtp.join(""));
      }, 100);
    }
  };

  const handleResendOtp = () => {
    setErrorMessage(""); // Clear error message when requesting new OTP
    resendOtp(
      { email: state?.email },
      {
        onSuccess: () => {
          notify("Verification email sent", "success");
        },
      },
      {
        onError: (error) => {
          setErrorMessage(error?.message || error?.error?.message);
          notify(error?.error?.message, "error");
        },
      }
    );
  };
  const handleKeyDown = (element, index) => {
    // focus on previous input
    if (element.key === "Backspace" && otp[index] === "") {
      if (element.target.previousSibling) {
        element.target.previousSibling.focus();
      }
    }
  };
  return (
    <>
      <div className="w-full flex">
        <div className="login-banner w-50">
          <img className="w-full h-screen" src={loginImage} alt="Login"></img>
        </div>

        <div className="box">
          <div className="form-side">
            <div
              style={{
                height: "3.125rem;",
                width: "11.53rem",
                marginBottom: "3.12rem",
              }}
            >
              <Logo />
            </div>
            <div>
              <span className="h4 white">OTP Verification</span>
              <br />
              <span className="regularS gray-300">
                Enter your otp code sent to your email
              </span>
            </div>

            {/* Add error message display */}
            {errorMessage && (
              <div className="m-t-16 p-4 rounded bg-[#ff000015] border border-[#ff000030]">
                <p className="text-[#ff6b6b] text-sm">{errorMessage}</p>
              </div>
            )}

            <div className="flex-row justify-space-between m-t-24">
              {Array(6)
                .fill("")
                .map((_, index) => (
                  <input
                    type="otp"
                    name="otp"
                    maxLength="1"
                    key={index}
                    value={otp[index]}
                    onChange={(e) => handleChange(e.target, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onKeyUp={(e) => handleKeyDown(e, index)}
                    onFocus={(e) => e.target.select()}
                    onPaste={index === 0 ? handlePaste : null} // Only add paste handler to first input
                    style={{ textAlign: "center", color: "white" }}
                  />
                ))}
            </div>

            <div className="w-100 m-t-24">
              <Button
                variety="filled"
                className="w-100 primary-button black"
                onClick={handleSubmit}
                height="56px"
                isLoading={isLoading}
                type="submit"
              >
                Verify
              </Button>
            </div>
            <div className="text-align-center cursor-pointer m-t-12">
              <span className="gray-300">Didn't receive a code?</span>
              <span
                className="green-400 cursor-pointer semiboldL"
                onClick={handleResendOtp}
              >
                {" "}
                Resend code
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpVerification;
