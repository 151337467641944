import React from 'react';
import Select from 'react-select';

export const TagSelector = ({ value, onChange, options, isDisabled }) => {
  const customStyles = {
    control: (base, { menuIsOpen, isFocused }) => ({
      ...base,
      background: 'var(--App-Background)',
      minHeight: '40px',
      height: '40px',
      borderRadius: '8px',
      padding: '0 8px',
      width: '95%',
      fontSize: '14px',
      borderColor: (menuIsOpen || isFocused) ? '#00E6C3' : '#9e9cae4d',
      boxShadow: 'none',
      transition: 'border-color 0.2s ease',
      '&:hover': {
        borderColor: (menuIsOpen || isFocused) ? '#00E6C3' : '#9e9cae4d'
      }
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0',
      height: '38px',
    }),
    input: (base) => ({
      ...base,
      margin: '0',
      padding: '0',
      fontSize: '14px',
    }),
    multiValue: (base, { data }) => ({
      ...base,
      backgroundColor: `${data.color}4D`,
      borderRadius: '4px',
      height: '24px',
      margin: '2px',
      display: 'flex',
      alignItems: 'center',
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: 'white',
      padding: '2px 6px',
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
      lineHeight: '1',
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: 'white',
      padding: '0 4px',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: 'transparent',
        color: '#ef4444',
        cursor: 'pointer'
      }
    }),
    // Dropdown menu styles
    menu: (base) => ({
      ...base,
      background: 'rgb(36,31,51)',
      border: '1px solid #9e9caf1f',
      borderRadius: '4px',
      padding: '4px',
      marginTop: '4px',
      animation: 'dropdownFade 0.1s ease-in-out',
      zIndex: 1000,
    }),
    option: (base, { isFocused, isSelected }) => ({
      ...base,
      backgroundColor: isSelected || isFocused
        ? 'rgba(255,255,255,0.1)'
        : 'transparent',
      color: 'white',
      padding: '8px 12px',
      cursor: 'pointer',
      borderRadius: '4px',
      transition: 'background-color 0.2s ease',
      '&:active': {
        backgroundColor: 'rgba(255,255,255,0.1)'
      }
    }),
  };

  return (
    <>
      <style>
        {`
          @keyframes dropdownFade {
            from {
              opacity: 0;
              transform: translateY(-4px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
        `}
      </style>
      <Select
        isMulti
        value={value}
        onChange={onChange}
        options={options}
        isDisabled={isDisabled}
        styles={customStyles}
        className="m-t-8"
        classNamePrefix="select"
        menuPlacement="bottom"
        menuPosition="fixed"
        placeholder="Select tags..."
        blurInputOnSelect={false}
      />
    </>
  );
}; 