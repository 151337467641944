import React from "react";
import "./CustomInputComponent.css";
import ReactDatePicker from "react-datepicker";
import TimePicker from "react-time-picker";

export const CustomInputComponent = ({ placeholder, icon }) => {
  return (
    <div className="input-container">
      <input
        className="quick-add-task-container create-project-input"
        placeholder={placeholder}
      />
      {icon && <div className="icon">{icon}</div>}
    </div>
  );
};

export const DatePickerInputComponent = ({
  selected,
  onChange,
  icon,
  placeholderText = "Due Date",
}) => {
  return (
    <div className="input-container">
      <ReactDatePicker
        selected={selected}
        fixedHeight
        onChange={onChange}
        className="quick-add-task-container create-project-input"
        placeholderText={placeholderText}
        dateFormat={"MM/dd/yyyy"}
      />
      {icon && <div className="icon">{icon}</div>}
    </div>
  );
};

export const TimePickerInputComponent = ({
  value,
  onChange,
  icon,
  placeholderText = "Start Time",
}) => {
  return (
    <div className="input-container bg-transparent">
      <TimePicker
        className={
          "time-picker p-12  w-100 quick-add-task-container create-project-input"
        }
        format="HH:mm"
        value={value}
        onChange={onChange}
        clockIcon={null}
        clearIcon={null}
        disableClock
        color="#fff"
      />
      {icon && <div className="time-icon">{icon}</div>}
    </div>
  );
};
