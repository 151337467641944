import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import BoxReveal from "src/components/magicui/box-reveal";
import Chart from "../../components/Chart/LineChart";
import DashboardComponent from "../../components/Dashboard/DashboardEventComponent";
import { BallTriangleLoader } from "../../components/Loader/Loader";
import { MetricsCardComponent } from "../../components/Metrics/MetricsCard";
import FormSelect from "../../components/Select/FormSelect";
import { ChartDropDown } from "../../constants/constant";
import { useDataQuery } from "../../hooks/crud/data.query";
import { API_END_POINTS } from "../../utils/api-endpoint";
import { INSTAGRAM_TOKEN } from "../../utils/constants";
import { useMutationCreate } from "../..//hooks/crud/mutation.create";
import { APP_ROUTES } from "../../utils/app-routes";
import { notify } from "../../components/Toast/ToastComponent";
import { useNavigate } from "react-router-dom";
import ProgressChart from "../../components/ProgressChart/ProgressChart";

const Dashboard = () => {
  const navigate = useNavigate();
  const [selectedChartValue, setSelectedChartValue] = useState("Weekly");
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    reset,
  } = useForm({});
  const {
    data: userData,
    isFetching,
    isLoading,
  } = useDataQuery({
    url: API_END_POINTS.profile,
    params: {},
  });
  const { data: socialOverviewData } = useDataQuery({
    url: API_END_POINTS.socialOverview,
    params: {},
  });
  const { data: chartData } = useDataQuery({
    url: API_END_POINTS.charts,
    params: {
      startDate:
        selectedChartValue === "Monthly"
          ? moment().subtract(1, "month").format("YYYY-MM-DD")
          : selectedChartValue === "Yearly"
          ? moment().subtract(1, "year").format("YYYY-MM-DD")
          : moment().subtract(6, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },
  });

  const { data: googleCalendarLinkedStatus } = useDataQuery({
    url: API_END_POINTS.googleCalendarLinkStatus,
    params: {},
  });

  const { mutate: storeInstagramToken } = useMutationCreate(
    API_END_POINTS.storeInstagramToken
  );
  const getInstagramToken = () => {
    const data = localStorage.getItem(INSTAGRAM_TOKEN);
    if (data) {
      storeInstagramToken(
        { access_token: data },
        {
          onSuccess(res) {
            navigate(APP_ROUTES.social);
            localStorage.removeItem(INSTAGRAM_TOKEN);
          },
          onError: (err) => {
            navigate(APP_ROUTES.social);
            notify(err?.message, "error");
            localStorage.removeItem(INSTAGRAM_TOKEN);
          },
        }
      );
    }
  };

  useEffect(() => {
    getInstagramToken();
  }, []);

  return (
    <div>
      <span className="gray-400 regularL">
        Task lists, event vibes, and social beats
      </span>
      {isLoading ? (
        <div className="align-center justify-center">
          <BallTriangleLoader />
        </div>
      ) : (
        <BoxReveal width="100%" boxColor={"transparent"} duration={0.5}>
          <div className="flex flex-col lg:flex-row lg:justify-between">
            <div className="lg:w-8/12 w-9/12 mb-8 lg:mb-0">
              <DashboardComponent
                className="w-full"
                googleCalendarStatus={googleCalendarLinkedStatus?.data}
              />
            </div>
            <div className="lg:w-4/12 w-9/12 lg:pl-5">
              <span className="white">Your Platform</span>
              <div className="flex-column">
                {socialOverviewData?.data
                  ?.filter((filter) => filter?.isActive)
                  ?.map((item, index) => (
                    <MetricsCardComponent
                      isView
                      platformName={item.platformName}
                      audienceCount={item?.audienceCount}
                      isLinked={item?.isLinked}
                    />
                  ))}
              </div>
              {userData && chartData?.data && (
                <ProgressChart 
                  chartData={chartData?.data}
                  selectedChartValue={selectedChartValue}
                  setSelectedChartValue={setSelectedChartValue}
                  withAnimation={false}
                  size="small"
                />
              )}
            </div>
          </div>
        </BoxReveal>
      )}
    </div>
  );
};

export default Dashboard;
