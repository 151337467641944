import React from 'react';
import './Tooltip.css';

const Tooltip = ({ children, text, className = '' }) => {
  return (
    <span className={`tooltip-container ${className}`}>
      {children}
      <span className="tooltip-box">
        {text}
      </span>
    </span>
  );
};

export default Tooltip;