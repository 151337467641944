import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Outlet } from "react-router-dom";
import Card from "../../components/Card/Card";
import { MetricsCardComponent } from "../../components/Metrics/MetricsCard";
import { useDataQuery } from "../../hooks/crud/data.query";
import { API_END_POINTS } from "../../utils/api-endpoint";
import Chart from "../../components/Chart/LineChart";
import moment from "moment";
import FormSelect from "../../components/Select/FormSelect";
import { useForm } from "react-hook-form";
import { ChartDropDown } from "../../constants/constant";
import BoxReveal from "src/components/magicui/box-reveal";
import ProgressChart from "../../components/ProgressChart/ProgressChart";

const Social = () => {
  const navigate = useNavigate();
  const [selectedChartValue, setSelectedChartValue] = useState("Weekly");
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    reset,
  } = useForm({});
  const { data: userData, isFetching } = useDataQuery({
    url: API_END_POINTS.profile,
    params: {},
  });
  const { data: socialOverviewData } = useDataQuery({
    url: API_END_POINTS.socialOverview,
    params: {},
  });
  const { data: chartData } = useDataQuery({
    url: API_END_POINTS.charts,
    params: {
      startDate:
        selectedChartValue === "Monthly"
          ? moment().subtract(1, "month").format("YYYY-MM-DD")
          : selectedChartValue === "Yearly"
          ? moment().subtract(1, "year").format("YYYY-MM-DD")
          : moment().subtract(6, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },
  });

  return (
    <div>
      <Outlet></Outlet>
      <span className="gray-400 regularL">
        Task lists, event vibes, and social beats
      </span>
      <div>
        <Card className={"card-second-wrapper mt-2 py-2"}>
          <span className="white regularL p-24">Overview</span>
          <div className="flex-row justify-space-between">
            {
              // isFetching ? (
              //   <div className="flex-row align-center">
              //     <Loader />
              //   </div>
              // ) : (
              socialOverviewData?.data
                ?.filter((filter) => filter?.isActive)
                ?.map((item, index) => (
                  <MetricsCardComponent
                    platformName={item.platformName}
                    audienceCount={item?.audienceCount}
                    navigate={navigate}
                    isLinked={item?.isLinked}
                  />
                ))
              // )
            }
          </div>
        </Card>
        {chartData?.data && (
          <ProgressChart 
            chartData={chartData?.data}
            selectedChartValue={selectedChartValue}
            setSelectedChartValue={setSelectedChartValue}
            height={500}
            withAnimation={true}
            size="large"
          />
        )}
      </div>
    </div>
  );
};

export default Social;
