import moment from "moment";
import { CalendarIcon } from "../../assets/svg/CalendarIcon";
import { CheckCircle } from "../../assets/svg/CheckCircle";
import { AnimatedList } from "../magicui/animated-list";
import "./ListView.css";
import React, { useEffect, useState, useRef } from "react";
import { ClockIcon, Pencil1Icon } from "@radix-ui/react-icons";
import { notify } from "../Toast/ToastComponent";
import { DatePicker } from "antd";
import { useMutationPatch } from "../../hooks/crud/mutation.patch";
import { API_END_POINTS } from "../../utils/api-endpoint";

export const ProjectListViewComponent = ({
  title,
  date,
  duration,
  onClick,
  handleMark,
  completed,
  projectData,
  data,
  handleEditTask,
  selectedProjectIndex,
  setData,
  onClickComplete = () => {},
  setSelectedData,
  task,
  refetch,
}) => {
  const [isFirstRender, setIsFirstRender] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const [editedDate, setEditedDate] = useState();
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [status, setStatus] = useState("Incomplete");
  const inputRef = useRef(null);
  // update the task
  const { mutate: patchTask } = useMutationPatch(
    `${API_END_POINTS.tasks}/${task?._id}`
  );

  const calculateEstimatedTime = (selectedEvent) => {
    const startDate = new Date(selectedEvent?.startDate);
    const endDate = new Date(selectedEvent?.endDate);

    const timeDifference = endDate - startDate;

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );

    return { days, hours, minutes };
  };

  const estimatedTime = calculateEstimatedTime(duration);
  const displayEstimatedTime = () => {
    const { days, hours, minutes } = estimatedTime;
    const parts = [];
    if (days > 0) parts.push(`${days} days`);
    if (hours > 0) parts.push(`${hours} hours`);
    if (minutes > 0) parts.push(`${minutes} minutes`);
    return parts.length > 0 ? parts.join(", ") : "0 days";
  };

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
    setShowStatusDropdown(false);
    notify(`Status changed to ${newStatus}`, "success");
  };

  useEffect(() => {
    const hasLoadedBefore = sessionStorage.getItem("hasLoadedBefore");

    if (!hasLoadedBefore) {
      setIsFirstRender(true);
      setTimeout(() => {
        sessionStorage.setItem("hasLoadedBefore", "true");
      }, 500);
    }
  }, [isFirstRender]);

  useEffect(() => {
    if (isEditingTitle && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditingTitle]);

  const handleEditComplete = (index, description, date) => {
    if (editedTitle === title) {
      setIsEditingTitle(false);
      return;
    } else {
      setIsEditingTitle(false);
      const updatedData = data?.map((item, i) => {
        // If the item has a taskLists array, add the new task to it
        if (i === selectedProjectIndex && item?.tasksList) {
          const updatedTasks = [...item?.tasksList];
          // console.log("updatedTasks", updatedTasks);

          updatedTasks[i].title = editedTitle;
          updatedTasks[i].startDate = editedDate;
          // updatedTasks[index].description = description;
          // updatedTasks[index].date = date;
        }
        return item;
      });

      setData(updatedData);
      patchTask(updatedData[selectedProjectIndex]?.tasksList?.[0], {
        onSuccess(res) {
          // if (selectedViewType === "List View") {
          //   setJustUpdated(true);
          // }
          // setIsModalOpen(false);
          // refetchDashboardData();
          notify("Task updated successfully", "success");
        },
        onError: (err) => {
          notify(err?.error?.message, "error");
        },
      });
    }
    // handleEditTask();
    // Add any additional logic needed for successful edit here
  };

  const handleEditDateComplete = (date) => {
    setShowDatePicker(false);
    const updatedTask = {
      ...task,
      endDate: date?.toISOString(),
      startDate: date?.toISOString()
    };

    patchTask(updatedTask, {
      onSuccess(res) {
        notify("Task updated successfully", "success");
        refetch?.();
      },
      onError: (err) => {
        notify(err?.error?.message, "error");
      },
    });
  };

  const isPastDate = new Date(date) < new Date();
  const dateClass = isPastDate ? "text-red-500" : "text-gray-300";

  const ListComponent = isFirstRender ? AnimatedList : "div";

  const selectedProject = projectData?.filter(
    (item) => data?.project === item?._id
  );

  return (
    <ListComponent>
      <div
        onClick={(e) => {
          if (!isEditingTitle) {
            onClick(e);
          }
        }}
        className="list-view-wrapper p-6 m-4 cursor-pointer relative rounded-md hover:bg-[rgba(38,43,75,1)]"
      >
        <div className="flex justify-between items-center">
          <div
            className="w-2/4 relative"
            onMouseEnter={() => setShowEditIcon(true)}
            onMouseLeave={() => setShowEditIcon(false)}
          >
            {isEditingTitle ? (
              <input
                ref={inputRef}
                type="text"
                value={editedTitle}
                onChange={(e) => setEditedTitle(e.target.value)}
                onBlur={handleEditComplete}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleEditComplete();
                  }
                }}
                className="text-lg font-semibold bg-transparent text-white border-none border-white focus:outline-none p-0"
              />
            ) : (
              <span className="text-white text-lg font-semibold">
                {selectedProject?.[0]
                  ? selectedProject?.[0]?.title + " - " + editedTitle
                  : editedTitle}
              </span>
            )}
            {showEditIcon && !isEditingTitle && (
              <Pencil1Icon
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditingTitle(true);
                  setSelectedData({ ...task });

                  // setSelectedData(data)
                }}
                className="absolute right-0 top-1/2 transform -translate-y-1/2 cursor-pointer text-white"
              />
            )}
          </div>
          <div className="flex items-center">
            {showDatePicker ? (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="z-10 rounded-md shadow-lg"
              >
                <DatePicker
                  className="bg-transparent"
                  selected={editedDate || new Date(date)}
                  onChange={(newDate) => {
                    setEditedDate(newDate);
                    handleEditDateComplete(newDate);
                  }}
                />
              </div>
            ) : (
              <div
                className="flex items-center pr-4 relative"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDatePicker(!showDatePicker);
                }}
              >
                <CalendarIcon color={isPastDate ? "#EF4870" : "#D1D5DB"} />
                <span className={`pl-2 text-sm ${dateClass}`}>
                  {editedDate
                    ? moment(editedDate?.toISOString()).format("MMM D YYYY")
                    : moment(date).format("MMM D YYYY")}
                </span>
              </div>
            )}
            {duration && (
              <div className="flex items-center pl-4">
                <ClockIcon color="#D1D5DB" />
                <span className="pl-2 text-white text-sm">
                  {displayEstimatedTime()}
                </span>
              </div>
            )}
          </div>
          <div
            className="flex items-center"
            onMouseEnter={() => setShowStatusDropdown(true)}
            // onMouseLeave={() => setShowStatusDropdown(false)}
            onClick={(e) => {
              e.stopPropagation();
              onClickComplete();
            }}
          >
            <CheckCircle />
            <span
              className={`pl-2 text-white text-sm ${
                completed ? "text-green-400" : "text-gray-400"
              }`}
            >
              {status}
            </span>
            {showStatusDropdown && (
              <div className="absolute top-full mt-2 right-0 bg-white text-black shadow-lg rounded-md p-2 w-32 z-10">
                {["To do", "In Progress", "Blocked", "Completed"].map(
                  (option) => (
                    <div
                      key={option}
                      onClick={() => handleStatusChange(option)}
                      className="cursor-pointer p-1 hover:bg-gray-100 text-sm"
                    >
                      {option}
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </ListComponent>
  );
};
