import React, { useEffect, useRef, useState } from "react";
import "./Dropdown.css";
import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";

const Dropdown = ({
  options,
  onOptionChange,
  onTop,
  initialOption,
  className = "",
  placeholder = "Select an option",
}) => {
  const [selectedOption, setSelectedOption] = useState(initialOption);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownTriggerRef = useRef(null);

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    const newSelectedOption = {
      ...option,
      label: option.displayLabel || option.label,
    };
    setSelectedOption(newSelectedOption);
    setIsOpen(false);
    onOptionChange(option);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <div className={`dropdown-container ${className}`} ref={dropdownRef}>
      <div
        className={`dropdown-trigger ${!selectedOption ? "placeholder" : ""}`}
        ref={dropdownTriggerRef}
        onClick={toggleDropdown}
      >
        {selectedOption ? selectedOption.label : placeholder}
        {isOpen ? (
          <ChevronUpIcon className="chevron-icon" />
        ) : (
          <ChevronDownIcon className="chevron-icon" />
        )}
      </div>

      {isOpen && (
        <ul className="dropdown-list">
          {options.map((option) => (
            <li
              key={option.value}
              className={`dropdown-option ${
                selectedOption?.value === option?.value ? "selected" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                handleOptionClick(option);
              }}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
