import React from "react";
import Button from "../Button";
import moment from "moment";
import { EditOutlined } from "../../assets/svg/EditOutlined";
import { ListViewComponent } from "../ListView/ListViewComponent";
import { ClockIcon } from "@radix-ui/react-icons";
import { CalendarIcon } from "src/assets/svg/CalendarIcon";
import StatusDropdown from "../StatusDropdown/StatusDropdown";
import { notify } from "../Toast/ToastComponent";
import { useState } from "react";



export const ModalViewDetails = ({ selectedEvent, contentType, completed, patchTask }) => {

  const [selectedData, setSelectedData] = useState();

  const calculateEstimatedTime = (selectedEvent) => {
    const startDate = new Date(selectedEvent?.startDate);
    const endDate = new Date(selectedEvent?.endDate);

    const timeDifference = endDate - startDate;

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );

    return { days, hours, minutes };
  };

  const estimatedTime = calculateEstimatedTime(selectedEvent);
  const displayEstimatedTime = () => {
    const { days, hours, minutes } = estimatedTime;
    const parts = [];
    if (days > 0) parts.push(`${days} days`);
    if (hours > 0) parts.push(`${hours} hours`);
    if (minutes > 0) parts.push(`${minutes} minutes`);
    return parts.length > 0 ? parts.join(", ") : "0 days";
  };

  const isProjectCompleted = (event) => {
    return selectedEvent.tasksList?.every((task) => task.completed);
  };

  const projectStatus = isProjectCompleted(selectedEvent)
    ? "Completed"
    : "Incomplete";

  const handleStatusChange = (value) => {
    const { _id, ...patchData } = selectedEvent;
    
    const updatedTask = { 
      ...patchData,
      status: value,
      completed: value === "COMPLETED"
    };
    
    patchTask(updatedTask, {
      onSuccess(res) {
        notify("Task status updated successfully", "success");
      },
      onError: (err) => {
        notify(err?.error?.message, "error");
      },
    });
  };

  return (
    <div className="flex-row justify-space-between w-100">
      <div className="p-20 w-100" style={{}}>
        {contentType === "Task" && (
          <div className="w-48 mb-4">
            <StatusDropdown 
              status={selectedEvent?.status}
              onStatusChange={handleStatusChange}
            />
          </div>
        )}
        <h4>{selectedEvent?.title}</h4>
        <div>
          <span className="gray-300 regularS">Task description:</span>
          <p className="white">{selectedEvent?.description}</p>
        </div>
        <div className="w-100 mt-4 max-h-24">
          {contentType === "Project" && (
            <div className="border-t-2 border-t-[#9e9caf1f] pt-4 max-h-2">
              <span className="white boldL">Project Tasks</span>
              <div className="overflow-y-auto max-h-80">
                {selectedEvent?.tasksList?.map((task, index) => (
                  <ListViewComponent
                    date={moment(task?.endDate).format("MMM D YYYY")}
                    title={task?.title}
                    completed={task?.completed}
                    task={task}
                    setSelectedData={setSelectedData}
                    onUpdateTitle={(newTitle) => {
                      console.log("Title updated:", newTitle);
                    }}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="w-30" style={{ background: "rgb(36,31,51)" }}>
        {contentType === "Project" && (
          <div className="flex-column border-b-1">
            <div className="p-12">
              <span className="gray-300 regularS">Start Date</span>
              <div className="flex-row align-center">
                <CalendarIcon />
                <span className="white pl-2">
                  {moment(selectedEvent?.startDate).format("MMM D YYYY")}
                </span>
                {/* <EditOutlined /> */}
              </div>
            </div>
          </div>
        )}
        <div className="flex-column border-b-1">
          <div className="p-12">
            <span className="gray-300 regularS">Due Date</span>
            <div className="flex-row align-center">
              <CalendarIcon />
              <span className="white pl-2">
                {moment(selectedEvent?.endDate).format("MMM D YYYY")}
              </span>
              {/* <EditOutlined /> */}
            </div>
          </div>
        </div>
        {selectedEvent?.priority && (
          <div className="flex-column border-b-1">
            <div className="p-12">
              <span className="gray-300 regularS">Priority</span>
              <div className="flex-row align-center justify-space-between">
                <span className="white" style={{ textTransform: "capitalize" }}>
                  {selectedEvent?.priority?.toLowerCase()}
                </span>
                {/* <EditOutlined /> */}
              </div>
            </div>
          </div>
        )}
        <div className="flex-column border-b-1">
          <div className="p-12">
            <span className="gray-300 regularS">
              Estimated time to complete
            </span>
            <div className="flex-row align-center">
              <ClockIcon color="#fff" />{" "}
              <span className="white pl-2">{displayEstimatedTime()}</span>
              {/* <EditOutlined /> */}
            </div>
          </div>
        </div>
        {contentType === "Project" && (
          <div className="flex-column border-b-1">
            <div className="p-12">
              <span className="gray-300 regularS">Tags</span>
              <div className="flex gap-2 mt-2">
                {selectedEvent?.tags?.map((tag) => (
                  <span 
                    key={tag._id}
                    className="px-3 py-1 rounded text-sm text-white" 
                    style={{ 
                      backgroundColor: tag.color.replace('#', ''),
                      background: `${tag.color}4D`
                    }}
                  >
                    {tag.name}
                  </span>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const EventModalViewDetails = ({ selectedEvent, selectedTab }) => {
  const calculateEstimatedTime = (selectedEvent) => {
    const startDate = new Date(selectedEvent?.startDate);
    const endDate = new Date(selectedEvent?.endDate);

    const timeDifference = endDate - startDate;

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );

    return { days, hours, minutes };
  };

  const estimatedTime = calculateEstimatedTime(selectedEvent);
  const displayEstimatedTime = () => {
    const { days, hours, minutes } = estimatedTime;
    const parts = [];
    if (days > 0) parts.push(`${days} days`);
    if (hours > 0) parts.push(`${hours} hours`);
    if (minutes > 0) parts.push(`${minutes} minutes`);
    return parts.length > 0 ? parts.join(", ") : "0 days";
  };
  return (
    <div className="flex-row justify-space-between w-100">
      <div className="p-20 w-100" style={{}}>
        <h4>{selectedEvent?.title}</h4>
        <div className="max-h-80 overflow-y-auto">
          {selectedEvent?.eventDetails && (
            <div className="mt-5">
              <span className="gray-300 regularS">Event description:</span>
              <p
                className="white overflow-ellipsis"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "pre-wrap",
                }}
              >
                {selectedEvent?.eventDetails}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="w-30" style={{ background: "rgb(36,31,51)" }}>
        <div className="flex-column border-b-1">
          <div className="p-12">
            <span className="gray-300 regularS">Start Date</span>
            <div className="flex-row align-center">
              <CalendarIcon />
              <span className="white pl-2">
                {moment(selectedEvent?.startDate).format("MMM D YYYY")}
              </span>
              {/* <EditOutlined /> */}
            </div>
          </div>
        </div>

        <div className="flex-column border-b-1">
          <div className="p-12">
            <span className="gray-300 regularS">End Date</span>
            <div className="flex-row align-center">
              <CalendarIcon />
              <span className="white pl-2">
                {moment(selectedEvent?.endDate).format("MMM D YYYY")}
              </span>
              {/* <EditOutlined /> */}
            </div>
          </div>
        </div>
        {selectedEvent?.priority && (
          <div className="flex-column border-b-1">
            <div className="p-12">
              <span className="gray-300 regularS">Priority</span>
              <div className="flex-row align-center justify-space-between">
                <span className="white" style={{ textTransform: "capitalize" }}>
                  {selectedEvent?.priority?.toLowerCase()}
                </span>
                {/* <EditOutlined /> */}
              </div>
            </div>
          </div>
        )}
        {selectedEvent?.status && (
          <div className="flex-column border-b-1">
            <div className="p-12">
              <span className="gray-300 regularS">Status</span>
              <div className="flex-row align-center justify-space-between">
                <span className="white">
                  {selectedEvent?.status}
                </span>
                {/* <EditOutlined /> */}
              </div>
            </div>
          </div>
        )}
        <div className="flex-column border-b-1">
          <div className="p-12">
            <span className="gray-300 regularS">
              Estimated time to complete
            </span>
            <div className="flex-row align-center">
              <ClockIcon color="#fff" />{" "}
              <span className="white pl-2">{displayEstimatedTime()}</span>
              {/* <EditOutlined /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
