import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import moment from "moment";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { AddPreIcon } from "../../assets/svg/AddPreIcon";
import { DeleteOutlined } from "../../assets/svg/DeleteOutlined";
import { MinusIcon, PlusIcon } from "../../assets/svg/PlusIcon";
import { PlusIconOutlined } from "../../assets/svg/PlusIconOutlined";
import { SearchOutlined } from "../../assets/svg/SearchOutliined";
import Button from "../../components/Button";
import Card from "../../components/Card/Card";
import { ListViewComponent } from "../../components/ListView/ListViewComponent";
import { DashboardTab, ViewType } from "../../constants/constant";
import { useDataQuery } from "../../hooks/crud/data.query";
import { useMutationCreate } from "../../hooks/crud/mutation.create";
import { useMutationDelete } from "../../hooks/crud/mutation.delete";
import { useMutationPatch } from "../../hooks/crud/mutation.patch";
import { addTaskToProject } from "../../services/taskService";
import { API_END_POINTS } from "../../utils/api-endpoint";
import Calendar from "../Calendar/Calendar";
import Dialog from "../Dialog/Dialog";
import Modal, { CreateModal } from "../Modal/Modal";
import { ModalViewDetails } from "./ModalView";
import { QuickAddComponent } from "./QuickAddComponent";

import { CreateProjectForm } from "../CreateProject/CreateProject";
import { CreateTaskForm } from "../CreateTask/CreateTask";
import FormSelect from "../Select/FormSelect";
import { notify } from "../Toast/ToastComponent";
import { priorityData, statusData } from "../CreateTask/constant";
import BoxReveal from "../magicui/box-reveal";
import { BallTriangleLoader } from "../Loader/Loader";
import { ProjectListViewComponent } from "../ListView/ProjectListViewComponent";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import TimelineView from "../Timeline/TimelineView";
import { viewOptions } from "../Timeline/TimelineView";
import Dropdown from "../Dropdown/Dropdown";

const DashboardComponent = ({
  className,
  isEventTab,
  timelineData,
  isLoadingTimeline,
  googleCalendarStatus,
  ...props
}) => {
  const savedViewTypeRef = useRef(null);
  const [justUpdated, setJustUpdated] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [selectedTab, setSeletedTab] = useState("Tasks");
  const [showProjectTask, setShowProjectTask] = useState(false);
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(-1);
  const isUpdatingRef = useRef(false);

  const [selectedViewType, setSelectedViewType] = useState(() => {
    if (!isUpdatingRef.current) {
      return localStorage.getItem("selectedViewType") || "List View";
    }
    return "Timeline View";
  });
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [data, setData] = useState();
  const [selectedData, setSelectedData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isEditButton, setIsEditButton] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedPriority, setSelectedPriority] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [confirmationDialog, setConfirmationDialog] = useState(false);

  const [timelineView, setTimelineView] = useState(viewOptions[0]);

  const [pendingTaskUpdate, setPendingTaskUpdate] = useState(null);

  const [isTimelineUpdate, setIsTimelineUpdate] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    reset,
  } = useForm({});

  const {
    data: dashboardData,
    refetch: refetchDashboardData,
    error: dashboardError,
  } = useDataQuery({
    url: API_END_POINTS.dashboard,
    params: {},
  });

  const {
    data: tasksData,
    refetch: refetchTaskData,
    error: taskError,
  } = useDataQuery({
    url: API_END_POINTS.tasks,
    params: {
      search: searchText,
    },
    skip: !searchText,
  });

  console.log("tasksData", tasksData);

  const {
    data: projectData,
    refetch: refetchProjectData,
    error: projectError,
  } = useDataQuery({
    url: API_END_POINTS.project,
    params: {},
  });

  const { data: userData } = useDataQuery({
    url: API_END_POINTS.profile,
    params: {},
  });

  const { data: projectCategoryData } = useDataQuery({
    url: API_END_POINTS.projectCategory,
    params: {},
  });

  const { mutate: patchTask } = useMutationPatch(
    `${API_END_POINTS.tasks}/${selectedData?._id}`
  );

  const { mutate: patchTaskList } = useMutationPatch(`${API_END_POINTS.tasks}`);

  const { mutate: deleteTask } = useMutationDelete(
    API_END_POINTS.tasks,
    selectedData?._id
  );

  const { mutate: createTask } = useMutationCreate(API_END_POINTS.tasks);

  const { mutate: deleteProject } = useMutationDelete(
    API_END_POINTS.project,
    selectedData?._id
  );

  const [modalContentType, setModalContentType] = useState(null);

  const handleTaskClick = (e) => {
    setIsModalOpen(true);
    setModalTitle("Task Details");
    setSelectedData(e);
    setModalContentType("Task");
  };

  const handleProjectClick = (e) => {
    setIsModalOpen(true);
    setModalTitle("Project Details");
    setSelectedData(e);
    setIsEditButton(false);
    setModalContentType("Project");
  };

  const handleSelectedTab = (e) => {
    setSeletedTab(e.target.innerText);
  };

  const handleSelectedViewType = (e) => {
    setSelectedViewType(e.target.innerText);
  };

  const handleProjectTask = (index) => {
    setShowProjectTask(!showProjectTask);
    setSelectedProjectIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const completedTasks = (index) => {
    return data?.[index]?.tasksList?.filter((task) => task?.completed);
  };

  const completedFilteredTasks = (index) => {
    return filteredData?.[index]?.tasksList?.filter((task) => task?.completed);
  };

  const [isQuickAddOpen, setIsQuickAddOpen] = useState(false);

  const [tempTask, setTempTask] = useState(null);

  const handleCreateTask = (clickedIndex) => {
    if (isQuickAddOpen) return;

    setIsQuickAddOpen(true);
    if (selectedTab === "Tasks") {
      const newTask = {
        date: new Date(),
        endDate: new Date(),
        title: "",
        isEditing: true,
      };
      setTempTask(newTask);
    } else if (selectedTab === "Projects") {
      const newTask = {
        date: new Date(),
        endDate: new Date(),
        title: "",
        isEditing: true,
        description: "",
      };
      setTempTask({ task: newTask, projectIndex: clickedIndex });
    }
  };

  const handleSaveTask = async () => {
    setIsQuickAddOpen(false);

    if (selectedTab === "Projects" && tempTask) {
      try {
        const response = await addTaskToProject({
          ...tempTask.task,
          projectId: data?.[tempTask.projectIndex]?._id,
        });
        notify("Task added successfully", "success");
        refetchProjectData();
        setTempTask(null);
        return response;
      } catch (err) {
        notify(err?.message || "Failed to add task", "error");
        console.log("err", err);
      }
    } else if (tempTask) {
      try {
        if (selectedProjectId) {
          await addTaskToProject({
            ...tempTask,
            projectId: selectedProjectId,
          });
          notify("Task added successfully", "success");
          refetchTaskData();
          setTempTask(null);
        } else {
          await createTask(tempTask, {
            onSuccess: () => {
              notify("Task added successfully", "success");
              refetchTaskData();
              setTempTask(null);
            },
            onError: (err) => {
              notify(err?.message || "Failed to create task", "error");
            },
          });
        }
      } catch (err) {
        notify(err?.message || "Failed to create task", "error");
        console.log("err", err);
      }
    }
  };

  const handleCancelTask = () => {
    setIsQuickAddOpen(false);
    setTempTask(null);
  };

  const handleEditTask = (index, title, description, date) => {
    if (selectedTab === "Projects") {
      const updatedData = data?.map((item, i) => {
        if (i === selectedProjectIndex && item?.tasksList) {
          const updatedTasks = [...item?.tasksList];
          updatedTasks[index].title = title;
          updatedTasks[index].description = description;
          updatedTasks[index].date = date;
          updatedTasks[index].startDate = date;
          updatedTasks[index].endDate = date;
        }
        return item;
      });

      setData(updatedData);
    } else {
      const updatedTasks = [...data];
      updatedTasks[index].title = title;
      updatedTasks[index].description = description;
      updatedTasks[index].date = date;
      updatedTasks[index].startDate = date;
      updatedTasks[index].endDate = date;
      setData(updatedTasks);
    }
  };

  const updateTask = (updatedData) => {
    const { _id, ...patchData } = updatedData;
    patchTask(patchData, {
      onSuccess(res) {
        if (selectedViewType === "List View") {
          setJustUpdated(true);
        }
        setIsModalOpen(false);
        refetchDashboardData();
        notify("Task updated successfully", "success");
      },
      onError: (err) => {
        notify(err?.error?.message, "error");
      },
    });
  };

  const handleMarkAsComplete = () => {
    const updatedData = {
      ...selectedData,
      completed: true,
      status: "COMPLETED",
    };

    patchTask(updatedData, {
      onSuccess(res) {
        setJustUpdated(true);
        setIsModalOpen(false);
        refetchDashboardData();
        notify(
          `${selectedTab === "Tasks" ? "Task" : "Project"} marked as complete`,
          "success"
        );
      },
      onError: (err) => {
        notify(err?.error?.message, "error");
      },
    });
  };

  const handleSelectProject = (value) => {
    setSelectedProjectId(value);
  };

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const overdueTasks = data?.filter(
    (item) =>
      new Date(item?.endDate) < currentDate &&
      !item?.completed &&
      item?.status !== "COMPLETED"
  );
  const upcomingTasks = data?.filter((item) => {
    if (!item?.endDate) return false;
    const taskDate = new Date(item?.endDate);
    taskDate.setHours(0, 0, 0, 0);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return (
      taskDate.getTime() > today.getTime() &&
      !item?.completed &&
      item?.status !== "COMPLETED"
    );
  });

  const completedTasksData = data?.filter(
    (item) => !item?.isEditing && item?.completed
  );

  const handleCreate = () => {
    if (selectedTab === "Projects") {
      setIsCreateModalOpen(true);
      setModalTitle("Create Project");
      setModalContentType("Project");
      setIsEdit(false);
      setSelectedData({});
    } else if (selectedTab === "Tasks") {
      setIsCreateModalOpen(true);
      setModalTitle("Create Task");
      setModalContentType("Task");
      setSelectedData({});
      setIsEdit(false);
    }
  };

  const handleDeleteTask = () => {
    setConfirmationDialog(true);
  };
  const handleDeleteProject = () => {
    setConfirmationDialog(true);
  };

  const handleDeleteConfirmationTask = () => {
    setIsButtonLoading(true);
    if (modalContentType === "Task") {
      deleteTask(
        {},
        {
          onSuccess(res) {
            setIsModalOpen(false);
            setJustUpdated(true);
            refetchDashboardData();
            setConfirmationDialog(false);
            setIsButtonLoading(false);
            notify("Task deleted successfully", "success");
          },
        }
      );
    } else if (modalContentType === "Project") {
      deleteProject(
        {},
        {
          onSuccess(res) {
            setIsModalOpen(false);
            setJustUpdated(true);
            refetchProjectData();
            setConfirmationDialog(false);
            setIsButtonLoading(false);
            notify("Project deleted successfully", "success");
          },
        }
      );
    }
  };

  const handleTaskUpdate = (task, newDates) => {
    if (!task._id) {
      notify("Error: Task ID is missing", "error");
      return;
    }

    savedViewTypeRef.current = selectedViewType;
    setSelectedData(task);
    setPendingTaskUpdate({ task, newDates });
  };

  const TIMELINE_SUPPORTED_TABS = ["Projects"];

  useLayoutEffect(() => {
    if (savedViewTypeRef.current) {
      if (TIMELINE_SUPPORTED_TABS.includes(selectedTab)) {
        setSelectedViewType(savedViewTypeRef.current);
      } else {
        setSelectedViewType("List View");
        savedViewTypeRef.current = null;
      }
    }
  }, [projectData, selectedTab]);

  useEffect(() => {
    if (pendingTaskUpdate && selectedData?._id) {
      const updatedTask = {
        startDate: pendingTaskUpdate.newDates.startDate,
        endDate: pendingTaskUpdate.newDates.endDate,
      };

      patchTask(updatedTask, {
        onSuccess(res) {
          notify("Task dates updated successfully", "success");
          refetchProjectData();
          setPendingTaskUpdate(null);
        },
        onError: (err) => {
          notify(err?.error?.message || "Failed to update task dates", "error");
          savedViewTypeRef.current = null;
          setPendingTaskUpdate(null);
        },
      });
    }
  }, [selectedData, pendingTaskUpdate]);

  useEffect(() => {
    if (
      savedViewTypeRef.current &&
      TIMELINE_SUPPORTED_TABS.includes(selectedTab)
    ) {
      return;
    }

    if (justUpdated) {
      if (selectedTab === "Tasks") {
        setData(tasksData?.data);
      } else if (selectedTab === "Projects") {
        setData(projectData?.data);
      }
    } else {
      if (selectedTab === "Tasks") {
        setData(tasksData?.data);
        setSelectedViewType("List View");
      } else if (selectedTab === "Projects") {
        setData(projectData?.data);
        if (!savedViewTypeRef.current) {
          setSelectedViewType("List View");
        }
      }
    }
  }, [
    dashboardData,
    selectedTab,
    projectData,
    justUpdated,
    tasksData,
    searchText,
  ]);

  useEffect(() => {
    if (projectError) {
      notify(projectError?.error?.message, "error");
    } else if (dashboardError) {
      notify(dashboardError?.error?.message, "error");
    }
  }, [projectError, dashboardError]);

  const isProjectCompleted = (project) => {
    return project.tasksList.every((task) => task.completed);
  };

  useEffect(() => {
    if (selectedTab === "Tasks") {
      let filteredTasks = dashboardData?.data?.tasks;

      if (selectedStatus) {
        filteredTasks = filteredTasks?.filter(
          (item) => item?.status === selectedStatus
        );
      }

      if (selectedPriority?.toUpperCase()) {
        filteredTasks = filteredTasks?.filter(
          (item) => item?.priority === selectedPriority?.toUpperCase()
        );
      }

      if (selectedStatus || selectedPriority) {
        setFilteredData(filteredTasks);
      } else {
        setFilteredData([]);
      }
    } else if (selectedTab === "Projects") {
      if (selectedStatus === "Completed") {
        setFilteredData(projectData?.data?.filter(isProjectCompleted));
      } else if (selectedStatus === "Incomplete") {
        setFilteredData(
          projectData?.data?.filter((project) => !isProjectCompleted(project))
        );
      } else {
        setFilteredData([]);
      }
    }
  }, [
    selectedStatus,
    selectedPriority,
    selectedTab,
    projectData,
    dashboardData,
  ]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setIsModalOpen(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleStatusChange = (value) => {
    const { _id, ...patchData } = selectedData;
    const updatedTask = { ...patchData, status: value };

    patchTask(updatedTask, {
      onSuccess(res) {
        notify("Task status updated successfully", "success");
      },
      onError: (err) => {
        notify(err?.error?.message, "error");
      },
    });
  };

  const handleUpdateTitle = (updatedTitle, taskId) => {
    setData((prevTasks) =>
      prevTasks.map((task) =>
        task._id === taskId ? { ...task, title: updatedTitle } : task
      )
    );
  };

  useEffect(() => {
    return () => console.log("Component unmounting");
  }, []);

  const setSelectedViewTypeWithDebug = (newValue) => {
    setSelectedViewType(newValue);
  };

  useEffect(() => {
    if (pendingTaskUpdate === null && isTimelineUpdate) {
      setIsTimelineUpdate(false);
    }
  }, [pendingTaskUpdate, isTimelineUpdate]);

  useEffect(() => {
    return () => {
      savedViewTypeRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (
      !TIMELINE_SUPPORTED_TABS.includes(selectedTab) &&
      selectedViewType === "Timeline View"
    ) {
      setSelectedViewType("List View");
      savedViewTypeRef.current = null;
    }
  }, [selectedTab]);

  const todayTasks = data?.filter((item) => {
    if (!item?.endDate) return false;
    const taskDate = new Date(item?.endDate);
    taskDate.setHours(0, 0, 0, 0);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return (
      taskDate.getTime() === today.getTime() &&
      !item?.completed &&
      item?.status !== "COMPLETED"
    );
  });

  const [showTasksType, setShowTasksType] = useState({
    today: true,
    overdue: true,
    upcoming: true,
    completed: true,
    toBeScheduled: true,
  });

  useEffect(() => {
    if (todayTasks?.length > 0) {
      setShowTasksType((prev) => ({
        ...prev,
        today: true,
      }));
    }
  }, []);

  const toBeScheduledTasks = data?.filter((task) => {
    const hasNoEndDate =
      !task?.endDate || isNaN(new Date(task?.endDate).getTime());
    const isNotCompleted = !task?.completed && task?.status !== "COMPLETED";
    return hasNoEndDate && isNotCompleted;
  });

  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) return;

    if (destination.droppableId !== source.droppableId) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    let tasksToReorder;
    switch (source.droppableId) {
      case "todayTasks":
        tasksToReorder = todayTasks;
        break;
      case "upcomingTasks":
        tasksToReorder = upcomingTasks;
        break;
      case "overdueTasks":
        tasksToReorder = overdueTasks;
        break;
      case "completedTasks":
        tasksToReorder = data?.filter(
          (task) => task.completed || task.status === "COMPLETED"
        );
        break;
      case "toBeScheduledTasks":
        tasksToReorder = toBeScheduledTasks;
        break;
      default:
        return;
    }

    const newTasksArray = Array.from(tasksToReorder);
    const [removed] = newTasksArray.splice(source.index, 1);
    newTasksArray.splice(destination.index, 0, removed);

    const tasksWithIndices = newTasksArray.map((task, index) => ({
      ...task,
      index,
    }));

    const newData = data.map((task) => {
      const matchingNewTask = tasksWithIndices.find((t) => t._id === task._id);
      return matchingNewTask || task;
    });

    setData(newData);

    patchTaskList(tasksWithIndices, {
      onSuccess: () => {
        notify("Task order updated successfully", "success");
        refetchTaskData();
      },
      onError: (err) => {
        notify(err?.error?.message || "Failed to update task order", "error");
        setData(data);
      },
    });
  };

  useEffect(() => {
    switch (selectedTab) {
      case "Tasks":
      case "Projects":
        setSelectedViewType("List View");
        break;
    }
  }, [selectedTab]);

  const renderViewTypeButtons = () => {
    const viewTypes = ViewType;

    return viewTypes.map((item) => (
      <Button
        key={item.id}
        onClick={handleSelectedViewType}
        variety={item.name === selectedViewType ? "filled" : "outlined"}
      >
        {item.name}
      </Button>
    ));
  };

  return (
    <div className={className}>
      <div className="flex-column">
        <Card>
          <div className="flex-row justify-space-between  border-b-1 items-center">
            <div
              className={`flex-row justify-space-between ${
                isEventTab ? "w-30" : "w-60"
              } p-12`}
            >
              {DashboardTab?.map((item, index) => (
                <div
                  className={`cursor-pointer card-tab w-45 text-align-center  ${
                    item?.tabName === selectedTab && "card-tab-selected"
                  }`}
                  onClick={handleSelectedTab}
                >
                  <span className="white regularS">{item?.tabName}</span>
                </div>
              ))}
            </div>

            <div className={`flex-row justify-space-between m-4 items-center`}>
              <Button
                className="flex-row mediumM"
                variety="filled"
                height={"48px"}
                onClick={handleCreate}
              >
                <AddPreIcon /> Create{" "}
                {selectedTab === "Projects" ? "Project" : "Task"}
              </Button>
            </div>
          </div>
          <div className="card-second-wrapper content-container">
            <div className="flex-row justify-space-between items-center">
              <div className="flex-row justify-space-between p-12">
                {renderViewTypeButtons()}
              </div>
              <div className="flex items-center space-x-4 mr-8">
                {selectedViewType !== "Timeline View" && (
                  <div className="relative flex items-center">
                    {showSearch && (
                      <div className="absolute right-0 transform translate-x-[-40px]">
                        <Controller
                          name={"search"}
                          control={control}
                          render={({ field }) => (
                            <input
                              className="quick-add-task-container create-project-input search-input"
                              style={{
                                background: "var(--App-Background)",
                                marginRight: 8,
                                width: "250px",
                              }}
                              placeholder="Search..."
                              value={searchText}
                              onChange={(e) => setSearchText(e.target.value)}
                            />
                          )}
                        />
                      </div>
                    )}
                    <div
                      className="quick-add-task-container align-center flex-row p-8 cursor-pointer z-10 flex items-center"
                      onClick={() => setShowSearch(!showSearch)}
                    >
                      <SearchOutlined />
                    </div>
                  </div>
                )}

                <div className="flex items-center space-x-4">
                  {selectedTab === "Tasks" &&
                    selectedViewType === "List View" && (
                      <>
                        <div className="w-20 min-w-[120px]">
                          <FormSelect
                            control={control}
                            label="Priority"
                            id="priority"
                            options={priorityData}
                            onChange={(e) => {
                              if (e) {
                                setSelectedPriority(e.value);
                              } else {
                                setSelectedPriority("");
                              }
                            }}
                            name="priority"
                            placeholder="Priority"
                            isClearable
                            className="!mt-0"
                          />
                        </div>
                        <div className="w-20 min-w-[120px]">
                          <FormSelect
                            control={control}
                            label="Status"
                            id="status"
                            options={[
                              { label: "To Do", value: "TODO" },
                              { label: "In Progress", value: "IN_PROGRESS" },
                              { label: "Blocked", value: "BLOCKED" },
                              { label: "Completed", value: "COMPLETED" },
                            ]}
                            onChange={(e) => {
                              if (e) {
                                setSelectedStatus(e.value);
                              } else {
                                setSelectedStatus("");
                              }
                            }}
                            name="status"
                            placeholder="Status"
                            isClearable
                            className="!mt-0"
                          />
                        </div>
                      </>
                    )}
                  {selectedViewType === "Timeline View" && (
                    <Dropdown
                      options={viewOptions}
                      onOptionChange={setTimelineView}
                      initialOption={viewOptions[0]}
                      className="w-32"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="m-8">
              {selectedViewType === "List View" && selectedTab === "Tasks" && (
                <>
                  {!isQuickAddOpen && (
                    <div
                      className="cursor-pointer p-12 flex-row align-center mb-4 hover:bg-gray-800 rounded-md"
                      onClick={() => handleCreateTask()}
                    >
                      <PlusIconOutlined />
                      <span className="white">Create Task</span>
                    </div>
                  )}

                  {isQuickAddOpen && (
                    <QuickAddComponent
                      item={tempTask}
                      onSaveProject={handleSaveTask}
                      handleEditTask={(index, title, description, date) => {
                        setTempTask((prev) => ({
                          ...prev,
                          title,
                          description,
                          endDate: date,
                        }));
                      }}
                      projectData={projectData}
                      handleSelectProject={handleSelectProject}
                      handleCancelTask={handleCancelTask}
                    />
                  )}

                  <div className="task-list-container">
                    {selectedStatus || selectedPriority ? (
                      filteredData?.map((item, index) => (
                        <ListViewComponent
                          setFilteredData={setFilteredData}
                          refetch={refetchDashboardData}
                          key={item._id}
                          task={item}
                          index={index}
                          onClick={(e) => handleTaskClick(item)}
                          date={moment(item?.endDate).format("MMM D YYYY")}
                          title={item?.title}
                          completed={item?.completed}
                          status={item?.status}
                          onClickComplete={() => console.log("removed")}
                          setData={setData}
                          data={data}
                          setSelectedData={setSelectedData}
                          onUpdateTitle={(newTitle) =>
                            handleUpdateTitle(newTitle, item._id)
                          }
                        />
                      ))
                    ) : (
                      <>
                        {todayTasks?.length > 0 && (
                          <>
                            <span
                              onClick={() =>
                                setShowTasksType({
                                  ...showTasksType,
                                  today: !showTasksType?.today,
                                })
                              }
                              className="boldL white cursor-pointer flex align-center"
                            >
                              {showTasksType?.today ? (
                                <ChevronDownIcon />
                              ) : (
                                <ChevronUpIcon />
                              )}
                              <span className="p-4">
                                Today - {moment().format("MMM D")} (
                                {todayTasks?.length})
                              </span>
                            </span>
                            {showTasksType?.today && (
                              <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="todayTasks">
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                    >
                                      {todayTasks?.map((item, index) => (
                                        <ListViewComponent
                                          setFilteredData={setData}
                                          refetch={refetchDashboardData}
                                          key={item._id}
                                          task={item}
                                          index={index}
                                          isDraggable={true}
                                          onClick={(e) => handleTaskClick(item)}
                                          date={moment(item?.endDate).format(
                                            "MMM D YYYY"
                                          )}
                                          title={item?.title}
                                          completed={item?.completed}
                                          status={item?.status}
                                          duration={item}
                                          onClickComplete={async () => {
                                            await setSelectedData(item);
                                            handleMarkAsComplete();
                                          }}
                                          setData={setData}
                                          data={data}
                                          setSelectedData={setSelectedData}
                                          selectedData={selectedData}
                                          onUpdateTitle={(newTitle) =>
                                            handleUpdateTitle(
                                              newTitle,
                                              item._id
                                            )
                                          }
                                        />
                                      ))}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            )}
                          </>
                        )}

                        <span
                          onClick={() =>
                            setShowTasksType({
                              ...showTasksType,
                              overdue: !showTasksType?.overdue,
                            })
                          }
                          className="boldL white cursor-pointer flex align-center"
                        >
                          {showTasksType?.overdue ? (
                            <ChevronDownIcon />
                          ) : (
                            <ChevronUpIcon />
                          )}
                          <span className="p-4">
                            Overdue Tasks ({overdueTasks?.length})
                          </span>
                        </span>
                        {showTasksType?.overdue && (
                          <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="overdueTasks">
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  {overdueTasks
                                    ?.filter(
                                      (filterData) => !filterData?.completed
                                    )
                                    ?.map((item, index) => (
                                      <ListViewComponent
                                        setFilteredData={setData}
                                        refetch={refetchDashboardData}
                                        key={item._id}
                                        task={item}
                                        index={index}
                                        isDraggable={true}
                                        onClick={(e) => handleTaskClick(item)}
                                        date={moment(item?.endDate).format(
                                          "MMM D YYYY"
                                        )}
                                        title={item?.title}
                                        duration={item}
                                        completed={item?.completed}
                                        status={item?.status}
                                        onClickComplete={async () => {
                                          await setSelectedData(item);
                                          handleMarkAsComplete();
                                        }}
                                        setData={setData}
                                        data={data}
                                        setSelectedData={setSelectedData}
                                        selectedData={selectedData}
                                        onUpdateTitle={(newTitle) =>
                                          handleUpdateTitle(newTitle, item._id)
                                        }
                                      />
                                    ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        )}
                        <span
                          onClick={() =>
                            setShowTasksType({
                              ...showTasksType,
                              upcoming: !showTasksType?.upcoming,
                            })
                          }
                          className="boldL white cursor-pointer flex align-center"
                        >
                          {showTasksType?.upcoming ? (
                            <ChevronDownIcon />
                          ) : (
                            <ChevronUpIcon />
                          )}
                          <span className="p-4">
                            Upcoming Tasks (
                            {
                              upcomingTasks?.filter(
                                (filterData) => !filterData?.completed
                              )?.length
                            }
                            )
                          </span>
                        </span>
                        {showTasksType?.upcoming && (
                          <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="upcomingTasks">
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  {upcomingTasks
                                    ?.filter(
                                      (filterData) => !filterData?.completed
                                    )
                                    .map((item, index) => (
                                      <ListViewComponent
                                        setFilteredData={setData}
                                        refetch={refetchDashboardData}
                                        key={item._id}
                                        task={item}
                                        index={index}
                                        isDraggable={true}
                                        onClick={(e) => handleTaskClick(item)}
                                        date={moment(item?.endDate).format(
                                          "MMM D YYYY"
                                        )}
                                        title={item?.title}
                                        completed={item?.completed}
                                        status={item?.status}
                                        duration={item}
                                        onClickComplete={async () => {
                                          await setSelectedData(item);
                                          handleMarkAsComplete();
                                        }}
                                        setData={setData}
                                        data={data}
                                        setSelectedData={setSelectedData}
                                        selectedData={selectedData}
                                        onUpdateTitle={(newTitle) =>
                                          handleUpdateTitle(newTitle, item._id)
                                        }
                                      />
                                    ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        )}
                        <span
                          onClick={() =>
                            setShowTasksType({
                              ...showTasksType,
                              toBeScheduled: !showTasksType?.toBeScheduled,
                            })
                          }
                          className="boldL white cursor-pointer flex align-center"
                        >
                          {showTasksType?.toBeScheduled ? (
                            <ChevronDownIcon />
                          ) : (
                            <ChevronUpIcon />
                          )}
                          <span className="p-4">
                            To Be Scheduled ({toBeScheduledTasks?.length || 0})
                          </span>
                        </span>
                        {showTasksType?.toBeScheduled && (
                          <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="toBeScheduledTasks">
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  {toBeScheduledTasks?.map((item, index) => (
                                    <ListViewComponent
                                      setFilteredData={setData}
                                      key={item._id}
                                      task={item}
                                      index={index}
                                      isDraggable={true}
                                      onClick={(e) => handleTaskClick(item)}
                                      date={
                                        item?.endDate
                                          ? moment(item?.endDate).format(
                                              "MMM D YYYY"
                                            )
                                          : "No due date"
                                      }
                                      title={item?.title}
                                      completed={item?.completed}
                                      status={item?.status}
                                      duration={item}
                                      onClickComplete={async () => {
                                        await setSelectedData(item);
                                        handleMarkAsComplete();
                                      }}
                                      setData={setData}
                                      data={data}
                                      setSelectedData={setSelectedData}
                                      selectedData={selectedData}
                                      onUpdateTitle={(newTitle) =>
                                        handleUpdateTitle(newTitle, item._id)
                                      }
                                    />
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        )}
                        <span
                          onClick={() =>
                            setShowTasksType({
                              ...showTasksType,
                              completed: !showTasksType?.completed,
                            })
                          }
                          className="boldL white cursor-pointer flex align-center"
                        >
                          {showTasksType?.completed ? (
                            <ChevronDownIcon />
                          ) : (
                            <ChevronUpIcon />
                          )}
                          <span className="p-4">
                            Completed Tasks (
                            {data?.filter(
                              (task) =>
                                task.completed || task.status === "COMPLETED"
                            )?.length || 0}
                            )
                          </span>
                        </span>
                        {showTasksType?.completed && (
                          <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="completedTasks">
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  {data
                                    ?.filter(
                                      (task) =>
                                        task.completed ||
                                        task.status === "COMPLETED"
                                    )
                                    ?.map((item, index) => (
                                      <ListViewComponent
                                        setFilteredData={setData}
                                        key={item._id}
                                        task={item}
                                        index={index}
                                        isDraggable={true}
                                        onClick={(e) => handleTaskClick(item)}
                                        date={moment(item?.endDate).format(
                                          "MMM D YYYY"
                                        )}
                                        title={item?.title}
                                        completed={item?.completed}
                                        status={item?.status}
                                        duration={item}
                                        onClickComplete={async () => {
                                          await setSelectedData(item);
                                          handleMarkAsComplete();
                                        }}
                                        setData={setData}
                                        data={data}
                                        setSelectedData={setSelectedData}
                                        selectedData={selectedData}
                                        onUpdateTitle={(newTitle) =>
                                          handleUpdateTitle(newTitle, item._id)
                                        }
                                      />
                                    ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
              {selectedViewType === "List View" &&
              selectedTab === "Tasks" ? null : selectedViewType ===
                  "List View" && selectedTab === "Projects" ? (
                selectedStatus ? (
                  filteredData?.map((item, index) => {
                    return (
                      <div
                        className={`content-container ${
                          selectedTab === "Projects" ? "active" : ""
                        }`}
                      >
                        <div className="flex-row align-center cursor-pointer justify-space-between">
                          <div
                            className="flex-row align-center cursor-pointer"
                            // onClick={() => handleProjectTask(index)}
                          >
                            {" "}
                            <div
                              className="align-center"
                              onClick={() => handleProjectTask(index)}
                            >
                              {index === selectedProjectIndex ? (
                                <MinusIcon />
                              ) : (
                                <PlusIcon />
                              )}
                            </div>
                            <span
                              className="white p-8 boldM"
                              onClick={(e) => handleProjectClick(item)}
                            >
                              {item?.title}
                            </span>
                          </div>
                          <span className="green-400 regularS">
                            Status:{completedFilteredTasks(index)?.length}/
                            {filteredData?.[index].tasksList?.length}
                          </span>
                        </div>
                        {index === selectedProjectIndex && (
                          <>
                            {item?.tasksList?.map((task, index) => {
                              if (task?.isEditing) {
                                return (
                                  <QuickAddComponent
                                    onSaveProject={() => handleSaveTask(index)}
                                    handleEditTask={handleEditTask}
                                    item={task}
                                    index={index}
                                    projectData={projectData}
                                    handleSelectProject={handleSelectProject}
                                    handleCancelTask={() =>
                                      handleCancelTask(index)
                                    }
                                  />
                                );
                              } else {
                                return (
                                  <ProjectListViewComponent
                                    patchTask={patchTask}
                                    date={
                                      task?.date
                                        ? moment(task?.date).format(
                                            "MMM D YYYY"
                                          )
                                        : moment(task?.endDate).format(
                                            "MMM D YYYY"
                                          )
                                    }
                                    title={task?.title}
                                    onClick={(e) => handleTaskClick(task)}
                                    completed={task?.completed}
                                    selectedProjectIndex={selectedProjectIndex}
                                    setData={setData}
                                    data={data}
                                    setSelectedData={setSelectedData}
                                    task={task}
                                    refetch={refetchProjectData}
                                  />
                                );
                              }
                            })}
                            {!isQuickAddOpen && (
                              <div
                                className="cursor-pointer p-12 flex-row align-center"
                                onClick={() => handleCreateTask(index)}
                              >
                                <PlusIconOutlined />
                                <span className="white">Create Task</span>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    );
                  })
                ) : (
                  data?.map((item, index) => {
                    return (
                      <div
                        className={`content-container ${
                          selectedTab === "Projects" ? "active" : ""
                        }`}
                      >
                        <div className="flex-row align-center cursor-pointer justify-space-between">
                          <div
                            className="flex-row align-center cursor-pointer"
                            // onClick={() => handleProjectTask(index)}
                          >
                            {" "}
                            <div
                              className="align-center"
                              onClick={() => handleProjectTask(index)}
                            >
                              {index === selectedProjectIndex ? (
                                <MinusIcon />
                              ) : (
                                <PlusIcon />
                              )}
                            </div>
                            <span
                              className="white p-8 boldM"
                              onClick={(e) => handleProjectClick(item)}
                            >
                              {item?.title}
                            </span>
                          </div>
                          <span className="green-400 regularS">
                            Status:{completedTasks(index)?.length}/
                            {data?.[index].tasksList?.length}
                          </span>
                        </div>
                        {index === selectedProjectIndex && (
                          <>
                            {item?.tasksList?.map((task, index) => {
                              if (task?.isEditing) {
                                return (
                                  <QuickAddComponent
                                    onSaveProject={() => handleSaveTask(index)}
                                    handleEditTask={handleEditTask}
                                    item={task}
                                    index={index}
                                    projectData={projectData}
                                    handleSelectProject={handleSelectProject}
                                    handleCancelTask={() =>
                                      handleCancelTask(index)
                                    }
                                  />
                                );
                              } else {
                                return (
                                  <ProjectListViewComponent
                                    patchTask={patchTask}
                                    date={
                                      task?.date
                                        ? moment(task?.date).format(
                                            "MMM D YYYY"
                                          )
                                        : moment(task?.endDate).format(
                                            "MMM D YYYY"
                                          )
                                    }
                                    title={task?.title}
                                    onClick={(e) => handleTaskClick(task)}
                                    completed={task?.completed}
                                    selectedProjectIndex={selectedProjectIndex}
                                    setData={setData}
                                    data={data}
                                    setSelectedData={setSelectedData}
                                    task={task}
                                    refetch={refetchProjectData}
                                  />
                                );
                              }
                            })}
                            {!isQuickAddOpen && (
                              <div
                                className="cursor-pointer p-12 flex-row align-center"
                                onClick={() => handleCreateTask(index)}
                              >
                                <PlusIconOutlined />
                                <span className="white">Create Task</span>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    );
                  })
                )
              ) : selectedViewType === "Timeline View" &&
                selectedTab === "Projects" ? (
                <TimelineView
                  projects={projectData?.data}
                  orphanTasks={dashboardData?.data?.tasks?.filter(
                    (task) => !task.project
                  )}
                  selectedView={timelineView}
                  selectedTab={selectedTab}
                  onTaskUpdate={handleTaskUpdate}
                  onProjectComplete={handleMarkAsComplete}
                  onProjectEdit={() => {
                    setIsModalOpen(false);
                    setIsCreateModalOpen(true);
                    setModalTitle("Edit Projects");
                    setIsEdit(true);
                  }}
                  onProjectDelete={handleDeleteProject}
                  handleDeleteConfirmation={handleDeleteConfirmationTask}
                  deleteProject={deleteProject}
                  patchTask={patchTask}
                  notify={notify}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                  setModalTitle={setModalTitle}
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  handleMarkAsComplete={handleMarkAsComplete}
                  handleDeleteProject={handleDeleteProject}
                  handleDeleteTask={handleDeleteTask}
                  handleEdit={() => {
                    setIsModalOpen(false);
                    setIsCreateModalOpen(true);
                    setModalTitle("Edit Projects");
                    setIsEdit(true);
                  }}
                  refetchProjectData={refetchProjectData}
                  modalContentType={modalContentType}
                  setModalContentType={setModalContentType}
                  setJustUpdated={setJustUpdated}
                  data={data}
                  setData={setData}
                  refetchTaskData={refetchTaskData}
                />
              ) : selectedViewType === "Timeline View" &&
                selectedTab === "Tasks" ? (
                <TimelineView
                  projects={[]} // Empty since we're in Tasks view
                  orphanTasks={[]} // Empty since we're in Tasks view
                  tasksData={tasksData?.data || []} // Add .data to get the actual tasks array
                  selectedView={timelineView}
                  selectedTab={selectedTab}
                  onTaskUpdate={handleTaskUpdate}
                  patchTask={patchTask}
                  notify={notify}
                  deleteProject={deleteProject}
                  handleDeleteConfirmation={handleDeleteConfirmationTask}
                  handleMarkAsComplete={handleMarkAsComplete}
                  handleDeleteProject={handleDeleteProject}
                  handleDeleteTask={handleDeleteTask}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                  setModalTitle={setModalTitle}
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  refetchProjectData={refetchProjectData}
                  modalContentType={modalContentType}
                  setModalContentType={setModalContentType}
                  setJustUpdated={setJustUpdated}
                  data={data}
                  setData={setData}
                  refetchTaskData={refetchTaskData}
                />
              ) : null}
            </div>
          </div>
        </Card>
        <Modal
          isOpen={isModalOpen}
          // className={"card-app-background align-center"}
          onClose={() => setIsModalOpen(false)}
          title={modalTitle}
          handleMark={handleMarkAsComplete}
          completed={selectedData?.completed}
          status={selectedData?.status}
          handleDeleteTask={
            modalContentType === "Project"
              ? handleDeleteProject
              : handleDeleteTask
          }
          isEditButton={
            modalContentType === "Task" || modalContentType === "Project"
              ? true
              : isEditButton
          }
          onEditClick={() => {
            setIsModalOpen(false);
            setIsCreateModalOpen(true);
            setModalTitle(`Edit ${modalContentType}`);
            setIsEdit(true);
          }}
          completionStatus={true}
        >
          <ModalViewDetails
            selectedEvent={selectedData}
            contentType={modalContentType}
            completed={projectData?.data?.filter(isProjectCompleted)}
            patchTask={patchTask}
          />
        </Modal>

        <CreateModal
          isOpen={isCreateModalOpen}
          // className={"card-app-background align-center"}
          onClose={() => setIsCreateModalOpen(false)}
          title={modalTitle}
          className={"w-2/5"}
        >
          {modalContentType === "Project" ? (
            <CreateProjectForm
              setModal={setIsCreateModalOpen}
              refetch={refetchProjectData}
              isEdit={isEdit}
              selectedData={selectedData}
            />
          ) : modalContentType === "Task" ? (
            <CreateTaskForm
              setModal={setIsCreateModalOpen}
              selectedData={selectedData}
              isEdit={isEdit}
              refetch={refetchProjectData}
              setJustUpdated={setJustUpdated}
              projectData={projectData?.data}
              setData={setData}
              dataList={data}
            />
          ) : null}
        </CreateModal>
        <Dialog
          show={confirmationDialog}
          action1ButtonLabel={"Cancel"}
          action2ButtonLabel={"Delete"}
          handelAction1={() => setConfirmationDialog(false)}
          handelAction2={handleDeleteConfirmationTask}
          isLoading={isButtonLoading}
        >
          <div className="flex-col justify-center align-middle">
            <div
              style={{
                background: "rgba(37, 31, 52, 1)",
                width: 100,
                height: 100,
                borderRadius: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: 20,
                left: "40%",
              }}
            >
              <DeleteOutlined size={72} />
            </div>
            <div className="pt-28">
              <h3 className="white text-align-center ">
                You are about to delete a{" "}
                {modalContentType === "Task"
                  ? "task"
                  : modalContentType === "Project"
                  ? "project"
                  : "event"}
              </h3>
              <h5 className="white text-align-center">
                This will delete your{" "}
                {modalContentType === "Task"
                  ? "task"
                  : modalContentType === "Project"
                  ? "project"
                  : "event"}{" "}
                permanently.
              </h5>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default DashboardComponent;
