import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import "react-select-search/style.css";
import { TrashIcon } from "../../assets/svg/TrashIcon";
import Button from "../Button";
import FormSelect from "../Select/FormSelect";
import { ChevronDownIcon, ChevronRightIcon, CalendarIcon } from "@radix-ui/react-icons";
import { useDataQuery } from "../../hooks/crud/data.query";
import { TagSelector } from '../Select/TagSelector';
import { API_END_POINTS } from '../../utils/api-endpoint.js';

export const FormProject = ({
  isLastTaskComplete,
  isEdit,
  updateLoading,
  createLoading,
  setModal,
  selectedData,
  selectedCategory,
  onSubmit,
}) => {
  const { data: tagsData } = useDataQuery({
    url: API_END_POINTS.tags,
  });

  const [manuallyEditedTasks, setManuallyEditedTasks] = useState(new Set());

  const defaultValues = {
    title: isEdit ? selectedData?.title || selectedData?.name : "",
    description: isEdit ? selectedData?.description : "",
    startDate: isEdit ? selectedData?.startDate : selectedData?.startDate || new Date(),
    endDate: isEdit ? selectedData?.endDate : selectedData?.endDate || new Date(),
    tasksList: isEdit 
      ? (selectedData?.tasksList || selectedData?.tasks)?.map(
          ({ date, ...task }) => task 
        ) 
      : selectedData?.tasks?.map(task => ({
          ...task,
          endDate: new Date(selectedData.endDate.getTime() + (task.relativeDate || 0) * 24 * 60 * 60 * 1000)
        })) || [],
    tags: isEdit 
      ? selectedData?.tags?.map(tag => ({
          value: tag._id,
          label: tag.name,
          color: tag.color
        })) 
      : [],
  };

  const { handleSubmit, 
    control, 
    formState: { errors }, 
    register, 
    reset, 
    setValue, 
    watch } = useForm({ defaultValues });

  const projectEndDate = watch('endDate');

  useEffect(() => {
    if (!selectedData?.tasks || !projectEndDate) return;

    selectedData.tasks.forEach((task, index) => {
      if (manuallyEditedTasks.has(index)) return;

      if (task.relativeDate !== undefined) {
        const newTaskDate = new Date(projectEndDate.getTime() + task.relativeDate * 24 * 60 * 60 * 1000);
        setValue(`tasksList.${index}.endDate`, newTaskDate);
      }
    });
  }, [projectEndDate, selectedData?.tasks, manuallyEditedTasks]);

  const handleTaskDateChange = (index, date) => {
    setManuallyEditedTasks(prev => new Set(prev).add(index));
    setValue(`tasksList.${index}.endDate`, date);
    setValue(`tasksList.${index}.startDate`, date);
  };

  const [visibleTasks, setVisibleTasks] = useState({});
  const [taskTitles, setTaskTitles] = useState({});

  const { fields, remove, append } = useFieldArray({
    control,
    name: "tasksList",
  });

  const toggleTaskVisibility = (index) => {
    setVisibleTasks((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  useEffect(() => {
    if (fields?.length > 0 && Object.keys(visibleTasks).length === 0) {
      setVisibleTasks({ 0: true });
    }
  }, [fields]);

  const handleAddTask = () => {
    const newIndex = fields.length;
    append({
      title: "",
      description: "",
      startDate: null,
      endDate: null
    });
    setVisibleTasks({
      [newIndex]: true
    });
  };

  const handleTaskTitleBlur = (index, value) => {
    setTaskTitles(prev => ({
      ...prev,
      [index]: value
    }));
  };

  return (
    <form
      className="p-12 p-relative"
      onSubmit={handleSubmit(onSubmit)}
      style={{ marginBottom: 50, paddingLeft: 32, overflow: "visible" }}
    >
      <div className="pb-10 max-h-svh" style={{ overflow: "visible" }}>
        <div>
          <label className="create-label regularM">Title</label>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <input
                className="m-t-8 quick-add-task-container create-project-input"
                style={{ background: "var(--App-Background)", width: "95%" }}
                placeholder="Project title"
                required
                {...register("title")}
              />
            )}
          />
        </div>
        <div className="m-t-16">
          <label className="create-label regularM">Description</label>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <input
                className="m-t-8 quick-add-task-container create-project-input"
                style={{ background: "var(--App-Background)", width: "95%" }}
                placeholder="Project description"
                {...register("description")}
              />
            )}
          />
        </div>
        <div className="flex gap-4 m-t-16" style={{ width: "95%" }}>
          <div className="w-1/2 relative">
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <div className="relative">
                  <CalendarIcon 
                    className="absolute z-10 left-4 top-1/2 transform -translate-y-1/2 text-gray-300" 
                    style={{ pointerEvents: 'none' }}
                  />
                  <ReactDatePicker
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    className="w-full p-3 quick-add-task-container create-project-input"
                    style={{ background: "var(--App-Background)" }}
                    placeholderText="Start Date"
                    dateFormat="MM/dd/yyyy"
                  />
                </div>
              )}
            />
          </div>
          <div className="w-1/2 relative">
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <div className="relative">
                  <CalendarIcon 
                    className="absolute z-10 left-4 top-1/2 transform -translate-y-1/2 text-gray-300" 
                    style={{ pointerEvents: 'none' }}
                  />
                  <ReactDatePicker
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    className="w-full p-3 quick-add-task-container create-project-input"
                    style={{ background: "var(--App-Background)" }}
                    placeholderText="End Date"
                    dateFormat="MM/dd/yyyy"
                  />
                </div>
              )}
            />
          </div>
        </div>
        <div className="m-t-16">
          <label className="create-label regularM">Tags</label>
          <Controller
            name="tags"
            control={control}
            render={({ field }) => (
              <TagSelector
                value={field.value}
                onChange={(newValue) => {
                  field.onChange(newValue);
                }}
                options={tagsData?.data?.map(tag => ({
                  value: tag._id,
                  label: tag.name,
                  color: tag.color
                }))}
              />
            )}
          />
        </div>
        <div className="m-t-8 w-100">
          {fields?.map((task, index) => (
            <div key={index} className="justify-space-between pb-1 p-relative">
              <div className="w-100">
                <div className="flex-row align-center justify-between">
                  <div className="flex align-center">
                    {visibleTasks[index] ? (
                      <ChevronDownIcon
                        color="white"
                        onClick={() => toggleTaskVisibility(index)}
                        className="cursor-pointer"
                      />
                    ) : (
                      <ChevronRightIcon
                        color="white"
                        onClick={() => toggleTaskVisibility(index)}
                        className="cursor-pointer"
                      />
                    )}
                    <span className="boldM white pl-2">
                      {taskTitles[index] || (isEdit ? task?.title : "Add task")}
                    </span>
                  </div>
                  <div
                    className="trash-background cursor-pointer"
                    onClick={() => remove(index)}
                  >
                    {/* <TrashIcon color="white" /> */}
                    {/* <DeleteAction /> */}
                    <TrashIcon />
                  </div>
                </div>
                {visibleTasks[index] && (
                  <div className="px-4">
                    <div className="w-100 flex-row">
                      <div className="w-45">
                        <span className="gray-300 mediumM">Task Title</span>
                        <Controller
                          name={`tasksList[${index}].title`}
                          control={control}
                          defaultValue={task?.title || ""}
                          render={({ field }) => (
                            <input
                              className="m-t-8 w-80 quick-add-task-container create-project-input"
                              style={{ background: "var(--App-Background)" }}
                              key={index}
                              // value={task?.title}
                              placeholder="Task Title"
                              {...register(`tasksList[${index}].title`)}
                              onBlur={(e) => {
                                field.onBlur();
                                handleTaskTitleBlur(index, e.target.value);
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="flex-column w-50 m-t-8">
                        <span className="gray-300 mediumM">Due Date</span>
                        <Controller
                          name={`tasksList[${index}].endDate`}
                          control={control}
                          render={({ field }) => (
                            <div className="relative">
                              <CalendarIcon 
                                className="absolute z-10 left-4 top-1/2 text-gray-300" 
                                style={{ pointerEvents: 'none' }}
                              />
                              <ReactDatePicker
                                selected={field.value}
                                fixedHeight
                                onChange={(date) => handleTaskDateChange(index, date)}
                                className="m-t-8 w-40 quick-add-task-container create-project-input"
                                placeholderText="Due Date"
                                dateFormat={"MM/dd/yyyy"}
                              />
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="w-100">
                        <span className="gray-300 mediumM">
                          Task Description
                        </span>
                        <Controller
                          name={`tasksList[${index}].description`}
                          control={control}
                          defaultValue={task?.description || ""}
                          render={({ field }) => (
                            <textarea
                              className="m-t-8 w-100 quick-add-task-container create-project-input"
                              style={{
                                background: "var(--App-Background)",
                                minHeight: 30,
                                maxHeight: 200,
                                maxWidth: "95%",
                                minWidth: "95%",
                                padding: "8px 16px",
                              }}
                              placeholder="Task description"
                              {...field}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div
        className="p-absolute card-second-wrapper flex-row w-90 justify-space-between"
        style={{
          bottom: -35,
          left: 20,
          width: "95%",
          // right: 24,
        }}
      >
        <Button
          variety="outlined"
          className="m-t-16"
          disabled={!isLastTaskComplete}
          type="button"
          onClick={handleAddTask}
        >
          + Add Task
        </Button>
        <div>
          <Button
            variety="outlined"
            className="m-t-16"
            onClick={() => {
              setModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variety="filled"
            className="m-t-16"
            type="submit"
            isLoading={createLoading || updateLoading}
          >
            Submit
          </Button>
        </div>
      </div>
    </form>
  );
};
