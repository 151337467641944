import React from "react";
import { BadgeIcon } from "../assets/svg/BadgeIcon";
import { BookingIcon } from "../assets/svg/BookingIcon";
import { DashboardIcon } from "../assets/svg/DashboardIcon";
import { EventIcon } from "../assets/svg/EventIcon";
import { SocialIcon } from "../assets/svg/SocialIcon";
import { APP_ROUTES } from "./app-routes";
import { CalendarIcon } from "../assets/svg/CalendarIcon";

export const sidebarData = [
  {
    label: "Dashboard",
    path: APP_ROUTES.dashboard,
    icon: <DashboardIcon />,
  },
  {
    label: "Tasks/Projects",
    path: APP_ROUTES.eventAndTasks,
    icon: <EventIcon />,
  },
  {
    label: "Social",
    path: APP_ROUTES.social,
    icon: <SocialIcon />,
  },
  {
    label: "Calendar",
    path: APP_ROUTES.calendar,
    icon: <CalendarIcon color={"#fff"} />,
  },
];
