import React from "react";
import DatePicker from "react-datepicker";
import Button from "../Button";
import { CalendarIcon } from "@radix-ui/react-icons";
import Dropdown from "../Dropdown";

export const QuickAddComponent = ({
  item,
  projectData,
  onSaveProject,
  handleEditTask,
  index,
  handleSelectProject,
  handleCancelTask,
}) => {
  return (
    <div className="quick-add-task-container flex-column m-8">
      <input
        className="border-none text-base"
        type="text"
        placeholder="Task Name"
        value={item?.title}
        onChange={(e) =>
          handleEditTask(index, e.target.value, item?.description, item?.endDate)
        }
      />
      <textarea
        placeholder="Description"
        className="border-none pl-5 white bg-transparent text-sm resize-none min-h-12 focus:outline-none focus:border-none focus:pl-5"
        value={item?.description}
        onChange={(e) =>
          handleEditTask(index, item?.title, e.target.value, item?.endDate)
        }
      />
      <div className="flex m-3.5">
        <div className="relative w-1/5 mr-3">
          <CalendarIcon 
            className="absolute z-10 left-4 top-1/2 transform -translate-y-1/2 text-gray-300" 
            style={{ pointerEvents: 'none' }}
          />
          <DatePicker
            placeholderText="Due Date"
            selected={item?.endDate ? new Date(item?.endDate) : null}
            onChange={(date) =>
              handleEditTask(index, item?.title, item?.description, date || new Date())
            }
            className="w-full p-3 quick-add-task-container create-project-input"
            dateFormat="MMM d"
          />
        </div>
        {/* Can add est. time and Tags here */}
      </div>
      {/* Footer with Project select & save/cancel buttons */}
      <div className="flex-row justify-between p-12 align-center border-top-task-add-container">
        <Dropdown
          className="w-30"
          placeholder="Project"
          options={[
            ...((() => {
              return projectData?.data?.map(item => ({
                label: item.title,
                value: item._id
              })) || []
            })())
          ]}
          onOptionChange={(option) => handleSelectProject(option.value)}
        />
        <div className="flex-row gap-2">
          <Button variety="outlined" onClick={handleCancelTask}>
            Cancel
          </Button>
          <Button variety="filled" onClick={onSaveProject}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
