import React from 'react';
import { useForm } from "react-hook-form";
import FormSelect from "../Select/FormSelect";
import Chart from "../Chart/LineChart";
import BoxReveal from "../magicui/box-reveal";
import { ChartDropDown } from "../../constants/constant";

const ProgressChart = ({ 
  chartData, 
  selectedChartValue, 
  setSelectedChartValue,
  className = "",
  height,
  withAnimation = false,
  size = "large",
}) => {
  const { control } = useForm({});

  const chartContent = (
    <div className={`card-second-wrapper rounded-lg ${size === "small" ? "mt-2" : "mt-4"} ${className}`}>
      <div className="flex justify-between items-center">
        <span className="white text-2xl ml-8 mt-4">Your Progress</span>
        <div className="w-1/3 mr-12 my-4 max-w-[300px]">
          <FormSelect
            control={control}
            label="Status"
            id="status"
            options={ChartDropDown}
            onChange={(e) => {
              if (e) {
                setSelectedChartValue(e.value);
              } else {
                setSelectedChartValue("");
              }
            }}
            name="interval"
            placeholder={ChartDropDown.find(option => option.value === selectedChartValue)?.label || selectedChartValue}
          />
        </div>
      </div>
      <div className="mt-4 mr-4">
        <Chart 
          chartData={chartData} 
          height={height} 
          view={selectedChartValue}
          size={size}
          hideZeroYAxis={true}
        />
      </div>
    </div>
  );

  return withAnimation ? (
    <BoxReveal width="100%" boxColor={"transparent"} duration={0.5}>
      {chartContent}
    </BoxReveal>
  ) : chartContent;
};

export default ProgressChart; 