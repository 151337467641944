import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import "react-select-search/style.css";
import { useDataQuery } from "../../hooks/crud/data.query";
import { useMutationCreate } from "../../hooks/crud/mutation.create";
import { API_END_POINTS } from "../../utils/api-endpoint";
import Button from "../Button";
import FormSelect from "../Select/FormSelect";
import { notify } from "../Toast/ToastComponent";
import { TrashIcon } from "../../assets/svg/TrashIcon";
import { useMutationPatch } from "../../hooks/crud/mutation.patch";
import { EditOutlined } from "src/assets/svg/EditOutlined";
import { set } from "lodash";
import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import {
  CustomProjectIcon,
  SuggestedProjectIcon,
} from "src/assets/svg/CreateProjectIconGroup";
import { FormProject } from "./CreateProjectForm";

export const CreateProjectForm = ({
  setModal,
  refetch,
  isEdit,
  selectedData,
}) => {
  const [projectType, setProjectType] = useState(isEdit ? "custom" : null);
  const [selectedCategoryState, setSelectedCategoryState] = useState(null);

  const [visibleCategoryId, setVisibleCategoryId] = useState(null);
  const [showTaskList, setShowTaskList] = useState(false);
  const [suggestedProjectData, setSuggestedProjectData] = useState(null);

  const [selectSuggestProject, setSelectSuggestProject] = useState(false);

  const handleCategorySelect = (category) => {
    setProjectType(category);
    // setSelectedCategory(category);
  };
  const { data: categoriesData } = useDataQuery({
    url: API_END_POINTS.projectCategory,
  });
  const { mutate: createProject, isLoading: createLoading } = useMutationCreate(
    API_END_POINTS.project
  );
  const { mutate: updateProject, isLoading: updateLoading } = useMutationPatch(
    API_END_POINTS.project + "/" + selectedData?._id
  );
  const [selectedCategory, setSelectedCategory] = useState(null);
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      title: isEdit ? selectedData?.title : "",
      description: isEdit ? selectedData?.description : "",
      // category: isEdit ? selectedData?.category._id : "",
      tasksList: isEdit ? selectedData?.tasksList : [],
    },
    // resolver: yupResolver(),
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: "tasksList",
  });

  const onSubmit = (data) => {
    const updatedTasks = data?.tasksList.map(({ date, ...task }) => ({
      ...task,
      startDate: task.endDate,
      endDate: task.endDate,
    }));

    const formData = {
      ...data,
      category: selectedCategory?._id,
      tags: data.tags?.map(tag => tag.value) || [],
      tasksList: updatedTasks
    };

    isEdit
      ? updateProject(formData, {
          onSuccess(res) {
            setModal(false);
            notify("Project updated successfully", "success");
            refetch();
          },
        })
      : createProject(formData, {
          onSuccess(res) {
            setModal(false);
            notify("Project created successfully", "success");
            refetch();
          },
        });
  };

  useEffect(() => {
    if (selectedCategory && !isEdit) {
      setValue(
        "tasksList",
        selectedCategory.tasks.map((task) => ({
          title: task.title,
          description: task.description,
          startDate: task.startDate ? new Date(task.startDate) : null,
          endDate: task.endDate ? new Date(task.endDate) : null,
        }))
      );
    }
  }, [selectedCategory, isEdit]);

  useEffect(() => {
    if (isEdit && selectedData?.tasksList) {
      setValue(
        "tasksList",
        selectedData.tasksList.map((task) => ({
          title: task.title,
          description: task.description,
          startDate: task.startDate ? new Date(task.startDate) : null,
          endDate: task.endDate ? new Date(task.endDate) : null,
        }))
      );
    }
  }, [isEdit, selectedData, setValue]);

  const isLastTaskComplete =
    fields.length === 0 ||
    (fields[fields.length - 1] &&
      fields[fields.length - 1].title &&
      fields[fields.length - 1].description);

  const toggleTasksVisibility = (category) => {
    if (category?._id === visibleCategoryId && !showTaskList) {
      setShowTaskList(false);
    } else {
      setShowTaskList(true);
      setSuggestedProjectData(category);

      setVisibleCategoryId((prevIndex) =>
        prevIndex === category?._id ? -1 : category?._id
      );
    }
  };

  const handleSuggestedContinue = () => {
    if (!suggestedProjectData) return;

    const today = new Date();
    const endDate = new Date();

    // Find the minimum relative date from tasks
    const minRelativeDate = Math.min(
      ...suggestedProjectData.tasks
        .map(task => task.relativeDate || 0)
    );

    // Add the absolute value of minRelativeDate days to today
    endDate.setDate(today.getDate() + Math.abs(minRelativeDate));

    const prepopulatedData = {
      ...suggestedProjectData,
      startDate: today,
      endDate: endDate,
      tasks: suggestedProjectData.tasks.map(task => ({
        ...task,
        endDate: new Date(endDate.getTime() + (task.relativeDate || 0) * 24 * 60 * 60 * 1000)
      }))
    };

    setSuggestedProjectData(prepopulatedData);
    setSelectSuggestProject(true);
    setProjectType(null);
    setShowTaskList(false);
    console.log("continued");
  };

  useEffect(() => {
    if (categoriesData?.data?.length > 0) {
      setVisibleCategoryId(categoriesData.data[0].id);
    }
  }, [categoriesData]);

  useEffect(() => {
    if (isEdit && categoriesData?.data) {
      const selectedCategory = categoriesData.data.find(
        (option) => option?._id === selectedData?.category?._id
      );
      setSelectedCategory(selectedCategory);
    }
  }, [isEdit, selectedData, categoriesData]);

  return (
    <div
      className="w-full relative"
      style={{
        maxHeight: "500px", // Set this to your desired height
        overflowY: "auto",
      }}
    >
      {!projectType && !selectSuggestProject && !isEdit ? (
        <div className="flex justify-center items-center py-8 ">
          <div className=" text-white rounded-lg p-6 flex space-x-8 ">
            <div
              className="flex-1 text-center border p-12 rounded-lg border-[#9e9cae4d] cursor-pointer transition-all duration-300 ease-in-out hover:bg-[var(--App-Background-Hover)]"
              onClick={() => handleCategorySelect("custom")}
            >
              <div className="p-4 rounded-full inline-block mb-4">
                <CustomProjectIcon />
              </div>
              <h2 className="text-xl font-semibold">Create a Custom Project</h2>
              <p className="text-gray-400">You can create as you want</p>
            </div>
            <div
              className="flex-1 text-center border p-12 rounded-lg border-[#9e9cae4d] cursor-pointer transition-all duration-300 ease-in-out hover:bg-[var(--App-Background-Hover)]"
              onClick={() => handleCategorySelect("suggested")}
            >
              <div className="p-4 rounded-full inline-block mb-4">
                <SuggestedProjectIcon />
              </div>
              <h2 className="text-xl font-semibold">
                Select a suggested project
              </h2>
              <p className="text-gray-400">Select a template</p>
            </div>
          </div>
        </div>
      ) : !projectType && selectSuggestProject && !showTaskList ? (
        <FormProject
          isLastTaskComplete={isLastTaskComplete}
          isEdit={true}
          updateLoading={updateLoading}
          createLoading={createLoading}
          setModal={setModal}
          selectedData={suggestedProjectData}
          onSubmit={onSubmit}
          selectedCategory={selectedCategory}
        />
      ) : null}
      {projectType === "custom" ? (
        <FormProject
          isLastTaskComplete={isLastTaskComplete}
          isEdit={isEdit}
          updateLoading={updateLoading}
          createLoading={createLoading}
          setModal={setModal}
          selectedData={selectedData}
          onSubmit={onSubmit}
          selectedCategory={selectedCategory}
        />
      ) : projectType === "suggested" ? (
        <div className="pb-24">
          {categoriesData?.data?.map((category) => (
            <div
              className={`flex flex-col justify-between  p-12 border my-4 mx-4 rounded-lg border-[#9e9cae4d] cursor-pointer ${
                visibleCategoryId === category._id && "bg-[#4473DA]"
              }`}
              onClick={() => {
                toggleTasksVisibility(category);
                // setSelectedCategoryState(category);
                // handleCategorySelect("custom");
              }}
            >
              <div className="flex-row align-center justify-between">
                <span className="white text-capitalize boldM">
                  {category.name}
                </span>
                {visibleCategoryId === category._id && showTaskList ? (
                  <ChevronUpIcon color="white" />
                ) : (
                  <ChevronDownIcon color="white" />
                )}
              </div>
              {visibleCategoryId === category._id &&
                showTaskList &&
                category?.tasks?.map((task) => (
                  <div key={task.id}>
                    <span className="text-white regularM">{task?.title}</span>
                  </div>
                ))}
            </div>
          ))}
          <div className="absolute right-4 ">
            <div>
              <Button
                variety="outlined"
                className="m-t-16"
                onClick={() => {
                  setVisibleCategoryId(null);
                  setShowTaskList(false);
                  setProjectType(null);
                }}
              >
                Back
              </Button>
              <Button
                variety="filled"
                className="m-t-16"
                type="submit"
                disabled={!visibleCategoryId && !showTaskList}
                onClick={handleSuggestedContinue}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
