import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutationCreate } from "../../hooks/crud/mutation.create";
import { API_END_POINTS } from "../../utils/api-endpoint";
import { APP_ROUTES } from "../../utils/app-routes";
import { notify } from "../../components/Toast/ToastComponent";
import { INSTAGRAM_TOKEN } from "../../utils/constants";

const InstagramCallback = () => {
  const navigate = useNavigate();

  const { mutate: storeInstagramToken } = useMutationCreate(
    API_END_POINTS.storeInstagramToken
  );

  const storeToken = async (access_token) => {
    localStorage.removeItem(INSTAGRAM_TOKEN);
    localStorage.setItem(INSTAGRAM_TOKEN, access_token);
    // storeInstagramToken(
    //   { access_token },
    //   {
    //     onSuccess(res) {
    //       console.log("res", res);
    //       navigate(APP_ROUTES.social);
    //     },
    //     onError: (err) => {
    //       console.log("err in login", err);
    //       notify(err?.message, "error");
    //     },
    //   }
    // );
  };
  useEffect(() => {
    const url = new URL(window.location);
    const splittedValue = url?.hash?.split("=");
    if (splittedValue) {
      // const authorizationCode = searchParams.get("token");
      const access_token = splittedValue?.[1]?.split("&")[0];
      storeToken(access_token);
    }
  }, []);

  return (
    <div>
      <h3 className="white">Processing...</h3>
      {/* You can show a loading spinner or a message while the authorization process is in progress */}
    </div>
  );
};

export default InstagramCallback;
