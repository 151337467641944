import React, { useState } from "react";
import { ImpressionIcon } from "../../assets/svg/ImpressionIcon";
import { UserIcon } from "../../assets/svg/UserIcon";
import Card from "../../components/Card/Card";
import { useDataQuery } from "../../hooks/crud/data.query";
import { API_END_POINTS } from "../../utils/api-endpoint";
import { countryInitialsToName } from "../../utils/countryInitials";
import Button from "../../components/Button";
import { AddPreIcon } from "../../assets/svg/AddPreIcon";
import { InstagramIcon } from "../../assets/svg/InstagramIcon";
import { periodDataType } from "./data";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SpotifyIcon } from "../../assets/svg/SpotifyIcon";
import Dialog from "../../components/Dialog/Dialog";
import { useMutationCreate } from "../../hooks/crud/mutation.create";
import { notify } from "../../components/Toast/ToastComponent";
import { APP_ROUTES } from "../../utils/app-routes";
import Chart from "../../components/Chart/LineChart";
import FormSelect from "../../components/Select/FormSelect";
import { useForm } from "react-hook-form";
import moment from "moment";
import { ChartDropDown } from "../../constants/constant";
import { BallTriangleLoader } from "../../components/Loader/Loader";
import NumberTicker from "src/components/magicui/number-ticker";
import { ChevronLeftIcon } from "@radix-ui/react-icons";
import { EmptySocial } from "../../assets/svg/EmptySocial";
import queryString from "query-string";
import ProgressChart from "../../components/ProgressChart/ProgressChart";

const SocialOverviewPage = () => {
  const CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const AUTH_ENDPOINT = process.env.REACT_APP_SPOTIFY_AUTH_ENDPOINT;
  const RESPONSE_TYPE = "code";
  // const RESPONSE_TYPE = process.env.REACT_APP_SPOTIFY_RESPONSE_TYPE;
  const REDIRECT_URI = process.env.REACT_APP_SPOTIFY_REDIRECT_URI;
  const { platform } = useParams();
  const location = useLocation();
  const isLinked = location?.state?.isLinked;
  const navigate = useNavigate();
  const [selectedDuration, setSelectedDuration] = React.useState("today");
  const [confirmationDialog, setConfirmationDialog] = React.useState(false);
  const [selectedChartValue, setSelectedChartValue] = useState("Weekly");
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    reset,
  } = useForm({});
  const {
    data: userData,
    isFetching,
    isLoading,
    refetch: refetchUserData,
  } = useDataQuery({
    url: API_END_POINTS.profile,
    params: {},
  });
  const {
    data: chartData,
    isLoading: isLoadingSocialData,
    isFetching: isSocialDataFetching,
    error: isErrorOverview,
  } = useDataQuery({
    url: API_END_POINTS.charts,
    params: {
      social: platform,
      startDate:
        selectedChartValue === "Monthly"
          ? moment().subtract(1, "month").format("YYYY-MM-DD")
          : selectedChartValue === "Yearly"
          ? moment().subtract(1, "year").format("YYYY-MM-DD")
          : moment().subtract(6, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },
  });
  const { mutate: unlinkPlatform } = useMutationCreate(
    API_END_POINTS.unlinkSocial
  );
  // const { refetch: refetchUserData } = useDataQuery(API_END_POINTS.profile);
  let apiEndpoint;
  switch (platform) {
    case "instagram":
      apiEndpoint = API_END_POINTS.instagramOverview;
      break;
    // Add more cases for other platforms...
    default:
      apiEndpoint = API_END_POINTS.spotifyOverview; // Replace with your default API endpoint
  }
  const { data: overviewData } = useDataQuery({
    url: apiEndpoint,
    params: {
      period: selectedDuration === "today" ? "" : selectedDuration,
    },
  });

  const getCountryName = (initials) => {
    return countryInitialsToName[initials] || initials;
  };

  // Function to sort the country data
  function sortCountryData(countryData) {
    return countryData.sort((a, b) => {
      const countryA = getCountryName(a?.countryInitials);
      const countryB = getCountryName(b?.countryInitials);
      return countryA?.localeCompare(countryB);
    });
  }

  // Get the sorted country data
  const sortedCountryData = sortCountryData(
    overviewData?.data?.demographic?.country || []
  );

  // Calculate the total count of Male, Female, and Unspecified
  const totalM = overviewData?.data?.demographic?.gender?.map((item) => item.M);
  const totalF = overviewData?.data?.demographic?.gender?.reduce(
    (total, item) => total + item.F,
    0
  );
  const totalU = overviewData?.data?.demographic?.gender?.reduce(
    (total, item) => total + item.U,
    0
  );

  const sortedCountries = overviewData?.data?.demographic?.country
    ?.sort((a, b) => b.followersCount - a.followersCount)
    .slice(0, 10);

  const handleUnlinkPlatform = () => {
    unlinkPlatform(
      { provider: platform },
      {
        onSuccess: () => {
          setConfirmationDialog(false);
          notify("Platform unlinked successfully", "success");

          navigate(APP_ROUTES.social);
          refetchUserData();
        },
        onError: (err) => {
          notify(err?.message, "error");
        },
      }
    );
  };

  const onClickLogin = async (type) => {
    switch (type) {
      case "Spotify".toLowerCase():
        const queryParams = {
          client_id: CLIENT_ID,
          response_type: RESPONSE_TYPE,
          redirect_uri:
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000/callback"
              : REDIRECT_URI,
          scope: "user-read-private user-read-email", // Add any required scopes
          state: Math.random() * 100000,
        };
        window.location.href = `${AUTH_ENDPOINT}?${queryString.stringify(
          queryParams
        )}`;
        break;

      case "Instagram".toLowerCase():
        window.location.href = `https://www.facebook.com/v19.0/dialog/oauth?client_id=3712894485649552&display=popup&redirect_uri=${
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000/callback/instacallback"
            : "https://genesis-dev.soundofgenesis.com/callback/instacallback"
        }&response_type=token&scope=email,instagram_manage_insights,pages_show_list,business_management`;

        break;
      default:
        break;
    }
  };

  return (
    <div>
      <div className="p-absolute" style={{ top: 0 }}>
        <h1 className="white">Social & Streaming</h1>
        <span className="gray-400 regularL">
          Task lists, event vibes, and social beats
        </span>
      </div>
      {isLoadingSocialData || isSocialDataFetching ? (
        <div className="align-center justify-center">
          <BallTriangleLoader />
        </div>
      ) : (
        <div className="my-4">
          <Button
            className="my-8 flex-row"
            variety="outlined"
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon /> Back
          </Button>
          {isLinked ? (
            <div>
              <Card className={"card-second-wrapper "}>
                <div
                  className="flex-row justify-space-between p-16"
                  style={{ background: "rgba(90, 75, 126, 0.1)" }}
                >
                  <div className="flex-row align-center w-35 justify-space-between">
                    <div className="overview-platform-selector w-40">
                      {platform === "instagram" ? (
                        <InstagramIcon />
                      ) : (
                        <SpotifyIcon />
                      )}
                      <span className="regularS white">
                        {platform?.charAt(0)?.toUpperCase() +
                          platform?.toLowerCase()?.slice(1)}
                      </span>
                    </div>
                    <Card
                      className={"p-6 quick-add-task-container w-40"}
                      // style={{ height: 38 }}
                    >
                      <div className="flex-row align-center">
                        <div>{<UserIcon />}</div>
                        <div
                          className="flex-column"
                          style={{ paddingLeft: 12 }}
                        >
                          <span className="gray-400 regularXS">
                            Total Followers
                          </span>
                          <span className="gray-200 regularM">
                            <NumberTicker
                              value={
                                overviewData?.data?.overview?.totalFollowers
                                  ? overviewData?.data?.overview?.totalFollowers
                                  : 0
                              }
                            />
                          </span>
                        </div>
                      </div>
                    </Card>
                  </div>
                  <Button
                    className="flex-row mediumM"
                    variety="filled"
                    height={"48px"}
                    onClick={() => setConfirmationDialog(!confirmationDialog)}
                  >
                    <AddPreIcon />
                    {"Connected"}
                  </Button>
                </div>
                <div className="flex-row align-center justify-space-between">
                  <h3 className="white p-12">Overview</h3>
                  <div className="w-20 flex-row">
                    {periodDataType?.map((item, index) => (
                      <div className="p-4">
                        <span
                          className={`${
                            selectedDuration === item?.value
                              ? "green-400"
                              : "gray-300"
                          } cursor-pointer`}
                          onClick={() => setSelectedDuration(item?.value)}
                        >
                          {item?.label}{" "}
                          {periodDataType[periodDataType.length - 1]?.id ===
                          index + 1
                            ? ""
                            : "|"}{" "}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex-row justify-space-between flex-wrap p-8">
                  {/* <Card className={"w-15 p-24 m-t-8"}>
            <div className="white flex-column">
              <div style={{ height: 24 }}>{<UserIcon />}</div>
              <span>Total Followers</span>
              <span>{overviewData?.data?.overview?.totalFollowers}</span>
            </div>
          </Card> */}
                  <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
                    {overviewData?.data?.metrics?.map((item, index) => (
                      <Card key={index} className={"w-100 p-24 m-t-8"}>
                        <div className="white flex-column">
                          <div style={{ height: 24 }}>
                            <img src={item?.imageUrl} alt={item?.title} />
                          </div>
                          <span>{item?.title}</span>
                          <span>
                            <NumberTicker value={item?.value} />
                          </span>
                        </div>
                      </Card>
                    ))}
                  </div>
                </div>
              </Card>
              {platform === "instagram" ? (
                <div>
                  <div className="flex-row justify-space-between">
                    <Card className={"w-45 p-8 m-t-24"}>
                      <div>
                        <span className="white">Gender</span>
                        <div className="quick-add-task-container m-8 p-12">
                          <span className="white regularM">
                            {"Overall Summary"}
                          </span>
                          {overviewData?.data?.demographic?.gender?.map(
                            (item, index) => (
                              <div className="flex-row justify-space-between">
                                <span className="gray-400 regularXS">
                                  {item?.gender}
                                </span>
                                <span className="gray-200 regularM">
                                  {item.followersCount}&nbsp;
                                </span>
                              </div>
                            )
                          )}

                          {overviewData?.data?.demographic?.age?.map(
                            (item, index) => {
                              const totalCount = item.M + item.F + item.U;

                              const totalPercentage = (
                                (item?.followersCount /
                                  overviewData?.data?.overview
                                    ?.totalFollowers) *
                                100
                              ).toFixed(2);

                              return (
                                <div className="border-b-1">
                                  <div
                                    className="flex-row justify-space-between"
                                    style={{ padding: "12px 0" }}
                                  >
                                    <span className="white regularM">
                                      {item.group}
                                    </span>
                                    <span className="gray-200 regularM">
                                      <NumberTicker
                                        value={item?.followersCount}
                                      />{" "}
                                      ({totalPercentage}%)
                                    </span>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </Card>

                    <Card className={"w-50 p-8 m-t-24"}>
                      <div>
                        <div className="flex-row justify-space-between">
                          <span className="white">Countries</span>
                          <span className="white">Followers</span>
                        </div>
                        <div className="m-8 p-12">
                          {sortedCountries?.map((item, index) => (
                            <div className="border-b-1">
                              <div className="flex-row justify-space-between align-center p-8">
                                <span className="gray-400 regularXS">
                                  {getCountryName(item?.countryInitials)}
                                </span>
                                <span className="gray-200 regularM">
                                  <NumberTicker value={item.followersCount} />
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Card>

                    <Dialog
                      show={confirmationDialog}
                      action1ButtonLabel={"Cancel"}
                      action2ButtonLabel={"Unlink"}
                      handelAction1={() => setConfirmationDialog(false)}
                      handelAction2={() => {
                        handleUnlinkPlatform();
                      }}
                    >
                      <div>
                        <h3 className="white">
                          Are you sire you want to unlink {platform}?
                        </h3>
                      </div>
                    </Dialog>
                  </div>

                  <ProgressChart 
                    chartData={chartData?.data}
                    selectedChartValue={selectedChartValue}
                    setSelectedChartValue={setSelectedChartValue}
                    className="mt-20"
                    size="large"
                  />
                </div>
              ) : platform === "spotify" ? (
                <div>
                  <div className="flex-row justify-space-between">
                    {/* <Card className={"w-45 p-8 m-t-24"}>
                <div>
                  <span className="white">Gender</span>
                  <div className="quick-add-task-container m-8 p-12">
                    <span className="white regularM">
                      {"Overall Summary"}
                    </span>
                    {overviewData?.data?.demographic?.gender?.map(
                      (item, index) => (
                        <div className="flex-row justify-space-between">
                          <span className="gray-400 regularXS">
                            {item?.gender}
                          </span>
                          <span className="gray-200 regularM">
                            {item.followersCount}&nbsp;
                          </span>
                        </div>
                      )
                    )}

                    {overviewData?.data?.demographic?.age?.map(
                      (item, index) => {
                        const totalCount = item.M + item.F + item.U;

                        const totalPercentage = (
                          (item?.followersCount /
                            overviewData?.data?.overview?.totalFollowers) *
                          100
                        ).toFixed(2);

                        return (
                          <div className="border-b-1">
                            <div
                              className="flex-row justify-space-between"
                              style={{ padding: "12px 0" }}
                            >
                              <span className="white regularM">
                                {item.group}
                              </span>
                              <span className="gray-200 regularM">
                                <NumberTicker value={item?.followersCount} />{" "}
                                ({totalPercentage}%)
                              </span>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </Card> */}
                    <Card className={"w-45 p-8 m-t-24"}>
                      <div>
                        <div className="flex-row justify-space-between">
                          <span className="white">City</span>
                          <span className="white">Followers</span>
                        </div>
                        <div className="m-8 p-12">
                          {overviewData?.data?.demographics?.city?.map(
                            (item, index) => (
                              <div className="border-b-1">
                                <div className="flex-row justify-space-between align-center p-8">
                                  <span className="gray-400 regularXS">
                                    {/* {getCountryName(item?.countryInitials)} */}
                                    {item?.city}
                                  </span>
                                  <span className="gray-200 regularM">
                                    <NumberTicker
                                      value={item?.totalListeners}
                                    />
                                  </span>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </Card>

                    <Card className={"w-45 p-8 m-t-24"}>
                      <div>
                        <div className="flex-row justify-space-between">
                          <span className="white">Countries</span>
                          <span className="white">Followers</span>
                        </div>
                        <div className="m-8 p-12">
                          {overviewData?.data?.demographics?.country?.map(
                            (item, index) => (
                              <div className="border-b-1">
                                <div className="flex-row justify-space-between align-center p-8">
                                  <span className="gray-400 regularXS">
                                    {/* {getCountryName(item?.countryInitials)} */}
                                    {item?.country}
                                  </span>
                                  <span className="gray-200 regularM">
                                    <NumberTicker
                                      value={item?.totalListeners}
                                    />
                                  </span>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </Card>

                    <Dialog
                      show={confirmationDialog}
                      action1ButtonLabel={"Cancel"}
                      action2ButtonLabel={"Unlink"}
                      handelAction1={() => setConfirmationDialog(false)}
                      handelAction2={() => {
                        handleUnlinkPlatform();
                      }}
                    >
                      <div>
                        <h3 className="white">
                          Are you sure you want to unlink {platform}?
                        </h3>
                      </div>
                    </Dialog>
                  </div>

                  <ProgressChart 
                    chartData={chartData?.data}
                    selectedChartValue={selectedChartValue}
                    setSelectedChartValue={setSelectedChartValue}
                    className="mt-20"
                    size="large"
                  />
                </div>
              ) : null}
            </div>
          ) : (
            <div>
              <Card className={"card-second-wrapper "}>
                <div
                  className="flex-row justify-space-between p-16"
                  style={{ background: "rgba(90, 75, 126, 0.1)" }}
                >
                  <div className="flex-row align-center w-35 justify-space-between">
                    <div className="overview-platform-selector w-40">
                      {platform === "instagram" ? (
                        <InstagramIcon />
                      ) : (
                        <SpotifyIcon />
                      )}
                      <span className="regularS white">
                        {platform?.charAt(0)?.toUpperCase() +
                          platform?.toLowerCase()?.slice(1)}
                      </span>
                    </div>
                  </div>
                  <Button
                    className="flex-row mediumM"
                    variety="filled"
                    height={"48px"}
                    onClick={() => setConfirmationDialog(!confirmationDialog)}
                  >
                    <AddPreIcon />
                    {"Connected"}
                  </Button>
                </div>
                <div className="items-center justify-center flex flex-column min-h-96">
                  <EmptySocial />
                  <span className="white boldL p-24">
                    Connect your{" "}
                    {`${
                      platform === "instagram"
                        ? "Instagram Business"
                        : "Spotify"
                    }`}{" "}
                    account
                  </span>
                  <p className="white w-2/5 text-center">
                    Connect your{" "}
                    {`${
                      platform === "instagram"
                        ? "Instagram Business"
                        : "Spotify"
                    }`}{" "}
                    account to get insights on your posts, stories, audience
                    growth, reach, engagement and more!
                  </p>
                  <Button
                    variety="filled"
                    className="mt-8 flex-row mediumM"
                    onClick={() => onClickLogin(platform)}
                  >
                    <AddPreIcon /> Connect
                  </Button>
                </div>
              </Card>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SocialOverviewPage;
