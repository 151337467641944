import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import moment from 'moment';
import './TimelineTooltip.css';

export const TimelineBubbleTooltip = ({ children, content, style = {} }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const [visible, setVisible] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top',
    strategy: 'fixed',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      { name: 'offset', options: { offset: [0, 0] } },
      { 
        name: 'preventOverflow',
        options: {
          mainAxis: false,
          altAxis: false,
        }
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: [],
        },
      }
    ],
  });

  return (
    <>
      <div
        ref={setReferenceElement}
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
      >
        {children}
      </div>
      {visible && (
        <div
          ref={setPopperElement}
          style={{ ...styles.popper, ...style }}
          className="timeline-tooltip"
          {...attributes.popper}
        >
          <div className="timeline-tooltip-content">
            {content}
          </div>
          <div
            ref={setArrowElement}
            style={styles.arrow}
            className="timeline-tooltip-arrow"
          />
        </div>
      )}
    </>
  );
};