import React from "react";
import Dropdown from "../Dropdown/Dropdown";
import {
  ClockIcon,
  CircleIcon,
  CrossCircledIcon,
  CheckCircledIcon,
} from "@radix-ui/react-icons";
import "./StatusDropdown.css";

const StatusOption = ({ icon: Icon, label, color, showPrefix = false }) => (
  <div className="status-option">
    {showPrefix && <span className="gray-300 regularS mr-1">Status:</span>}
    <Icon className="status-icon" style={{ color }} />
    <span>{label}</span>
  </div>
);

const StatusDropdown = ({ status, onStatusChange, isDashboard }) => {
  const statusOptions = [
    {
      label: <StatusOption icon={CircleIcon} label="To Do" color="#06719A" />,
      value: "TODO",
      displayLabel: (
        <StatusOption
          icon={CircleIcon}
          label="To Do"
          color="#06719A"
          showPrefix={true}
        />
      ),
    },
    {
      label: (
        <StatusOption icon={ClockIcon} label="In Progress" color="#FF7700" />
      ),
      value: "IN_PROGRESS",
      displayLabel: (
        <StatusOption
          icon={ClockIcon}
          label="In Progress"
          color="#FF7700"
          showPrefix={true}
        />
      ),
    },
    {
      label: (
        <StatusOption icon={CrossCircledIcon} label="Blocked" color="#EF4870" />
      ),
      value: "BLOCKED",
      displayLabel: (
        <StatusOption
          icon={CrossCircledIcon}
          label="Blocked"
          color="#EF4870"
          showPrefix={true}
        />
      ),
    },
    {
      label: (
        <StatusOption
          icon={CheckCircledIcon}
          label="Completed"
          color="#4CAF50"
        />
      ),
      value: "COMPLETED",
      displayLabel: (
        <StatusOption
          icon={CheckCircledIcon}
          label="Completed"
          color="#4CAF50"
          showPrefix={true}
        />
      ),
    },
  ];

  const getCurrentStatusLabel = (status) => {
    const option = statusOptions.find((opt) => opt.value === status);
    return option?.displayLabel || statusOptions[0].displayLabel;
  };

  return (
    <Dropdown
      options={statusOptions}
      onOptionChange={(option) => onStatusChange(option.value)}
      initialOption={{
        label: getCurrentStatusLabel(status),
        value: status,
      }}
      className="status-dropdown"
      isDashboard={isDashboard}
    />
  );
};

export default StatusDropdown;
