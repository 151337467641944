import moment from "moment";
import { CalendarIcon } from "../../assets/svg/CalendarIcon";
import { CheckCircle } from "../../assets/svg/CheckCircle";
import { LocationIcon } from "../../assets/svg/LocationIcon";
import { AnimatedList } from "../magicui/animated-list";
import "./ListView.css";
import React, { useEffect, useRef, useState } from "react";
import {
  ClockIcon,
  Pencil1Icon,
  CircleIcon,
  CrossCircledIcon,
} from "@radix-ui/react-icons";
import { notify } from "../Toast/ToastComponent";
import { DatePicker } from "antd";
import { useMutationPatch } from "../../hooks/crud/mutation.patch";
import { API_END_POINTS } from "../../utils/api-endpoint";
import StatusDropdown from "../StatusDropdown/StatusDropdown";
import { Draggable } from "react-beautiful-dnd";
import { estimatedTimeData } from "src/constants/constant";
import Dropdown from "../Dropdown";

export const ListViewComponent = ({
  title,
  date,
  duration,
  onClick,
  handleMark,
  completed,
  projectData,
  data,
  handleEditTask,
  selectedProjectIndex,
  setData,
  onClickComplete = () => {},
  setSelectedData,
  selectedData,
  task,
  index,
  onUpdateTitle,
  isDraggable = false,
  refetch,
  setFilteredData,
}) => {
  const [isFirstRender, setIsFirstRender] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [editedDate, setEditedDate] = useState();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showDurationDropdown, setShowDurationDropdown] = useState(false);

  const [status, setStatus] = useState(task?.status ? task?.status : "TODO");
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);

  const inputRef = useRef(null);

  // update the task
  const { mutate: patchTask } = useMutationPatch(
    `${API_END_POINTS.tasks}/${task?._id}`
  );

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
    // setShowStatusDropdown(true);
  };

  const handleMouseLeave = () => {
    console.log("mouse leave");
    setHoveredIndex(null);
    // setShowStatusDropdown(false);
  };
  const calculateEstimatedTime = (selectedEvent) => {
    const startDate = new Date(selectedEvent?.startDate);
    const endDate = new Date(selectedEvent?.endDate);

    const timeDifference = endDate - startDate;

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );

    return { days, hours, minutes };
  };

  const estimatedTime = calculateEstimatedTime(duration);
  const displayEstimatedTime = () => {
    const { days, hours, minutes } = estimatedTime;
    const parts = [];
    if (days > 0) parts.push(`${days} days`);
    if (hours > 0) parts.push(`${hours} hours`);
    if (minutes > 0) parts.push(`${minutes} mins`);
    return parts.length > 0 ? parts.join(", ") : "0 mins";
  };

  const handleEditComplete = (index, title, description, date) => {
    setIsEditingTitle(false);
    const updatedTasks = { ...task, title: editedTitle };
    patchTask(updatedTasks, {
      onSuccess(res) {
        notify("Task updated successfully", "success");
        onUpdateTitle(editedTitle);
      },
      onError: (err) => {
        notify(err?.error?.message, "error");
      },
    });
  };

  const handleEditDateComplete = (date) => {
    setShowDatePicker(false);
    setEditedDate(date);
    const updatedTasks = {
      ...task,
      endDate: date?.toISOString(),
      startDate: date?.toISOString(),
    };

    console.log("Updating task:", task._id, "with date:", date);

    patchTask(updatedTasks, {
      onSuccess(res) {
        notify("Task updated successfully", "success");
      },
      onError: (err) => {
        notify(err?.error?.message, "error");
      },
    });
  };

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
    setShowStatusDropdown(false);
    setHoveredIndex(null);
    const updatedTasks = { ...task };
    updatedTasks.status = newStatus;
    // setData(updatedTasks);
    patchTask(updatedTasks, {
      onSuccess(res) {
        notify(`Status changed to ${newStatus}`, "success");
      },
      onError: (err) => {
        notify(err?.error?.message, "error");
      },
    });
  };

  const handleDurationChange = (option) => {
    const updatedTask = {
      ...task,
      estimatedTimeHours: option.value,
    };
    setFilteredData((prev) => {
      const updatedData = prev.map((item) => {
        if (item._id === task._id) {
          return updatedTask;
        }
        return item;
      });
      return updatedData;
    });

    patchTask(updatedTask, {
      onSuccess(res) {
        notify("Estimated time updated successfully", "success");
        // refetch();
      },
      onError: (err) => {
        notify(err?.error?.message, "error");
      },
    });
  };

  useEffect(() => {
    const hasLoadedBefore = sessionStorage.getItem("hasLoadedBefore");

    if (!hasLoadedBefore) {
      setIsFirstRender(true);
      setTimeout(() => {
        sessionStorage.setItem("hasLoadedBefore", "true");
      }, 500);
    }
  }, [isFirstRender]);

  const isPastDate =
    new Date(date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
  const dateClass = isPastDate ? "red" : "gray-300";

  const ListComponent = isFirstRender ? AnimatedList : "div";

  const selectedProject = projectData?.filter(
    (item) => data?.project === item?._id
  );

  const content = (
    <div
      onClick={(e) => {
        if (!isEditingTitle) {
          onClick(e);
        }
      }}
      className="list-view-wrapper h-16 p-6 m-8 cursor-pointer relative rounded-md hover:bg-[rgba(38,43,75,1)]"
    >
      <div className="flex justify-between items-center h-full">
        {/* TITLE & EDIT ICON */}
        <div
          className="inline-flex items-center"
          onClick={(e) => {
            e.stopPropagation();
            setIsEditingTitle(true);
            setSelectedData(task);
          }}
          onMouseEnter={() => setShowEditIcon(true)}
          onMouseLeave={() => setShowEditIcon(false)}
        >
          {isEditingTitle ? (
            <input
              ref={inputRef}
              type="text"
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
              onBlur={handleEditComplete}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleEditComplete();
                }
              }}
              className="text-lg font-semibold bg-transparent text-white border-none border-white focus:outline-none p-0"
            />
          ) : (
            <span className="text-white text-lg font-semibold pl-4">
              {selectedProject?.[0]
                ? selectedProject?.[0]?.title + " - " + editedTitle
                : title}
            </span>
          )}
          {showEditIcon && !isEditingTitle && (
            <Pencil1Icon
              onClick={(e) => {
                e.stopPropagation();
                setIsEditingTitle(true);
                setSelectedData(task);
              }}
              className="ml-2 cursor-pointer text-white"
            />
          )}
        </div>
        {/* DATE, DURATION & COMPLETE ICON */}
        <div className="flex-row items-center absolute right-0">
          <div className="flex items-center mr-4 w-32 justify-start">
            {" "}
            {/* Add margin to the right */}
            {!showDatePicker ? (
              // Render the calendar icon and date span when showDatePicker is false
              <div
                className="flex items-center pr-4 relative"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDatePicker(true);
                  setEditedDate(date ? new Date(date) : null);
                }}
              >
                <CalendarIcon color={isPastDate ? "#EF4870" : "#D1D5DB"} />
                <span className={`pl-2 text-sm ${dateClass}`}>
                  {editedDate
                    ? moment(editedDate?.toISOString()).isValid()
                      ? moment(editedDate?.toISOString()).format("MMM D YYYY")
                      : "Not set"
                    : date && moment(date).isValid()
                    ? moment(date).format("MMM D YYYY")
                    : "Not set"}
                </span>
              </div>
            ) : (
              // Render the DatePicker when showDatePicker is true
              <div
                className="mt-2 z-10 p-2 rounded-md shadow-lg"
                onClick={(e) => e.stopPropagation()}
              >
                <DatePicker
                  className="bg-transparent"
                  selected={editedDate || new Date(date)}
                  onChange={(newDate) => {
                    setEditedDate(newDate);
                    handleEditDateComplete(newDate);
                  }}
                />
              </div>
            )}
          </div>
          {duration && (
            <div
              className="flex items-center w-24 justify-start"
              // onMouseEnter={() => setShowDurationDropdown(true)}
              // onMouseLeave={() => setShowDurationDropdown(false)}
              onClick={(e) => {
                e.stopPropagation();
                setShowDurationDropdown(true);
              }}
            >
              {!showDurationDropdown && (
                <ClockIcon color={"var(--Genesis-Gray-300)"} />
              )}
              {showDurationDropdown ? (
                <div>
                  <Dropdown
                    className="w-[80px]"
                    options={estimatedTimeData}
                    onOptionChange={(option) => {
                      handleDurationChange(option);
                      setShowDurationDropdown(false);
                    }}
                    value={task.estimatedTime}
                    placeholder="Time"
                  />
                </div>
              ) : (
                <span className="regularS white p-1">
                  {task.estimatedTimeHours
                    ? task.estimatedTimeHours + " hours"
                    : "Not set"}
                </span>
              )}
            </div>
          )}
          {/* STATUS */}

          {hoveredIndex === task?._id ? (
            <div>
              <StatusDropdown
                showStatusText={false}
                status={status}
                onStatusChange={handleStatusChange}
              />
            </div>
          ) : (
            !completed && (
              <div
                className="flex items-center w-32 justify-start"
                // onMouseEnter={() => handleMouseEnter(task?._id)}
                onClick={(e) => {
                  e.stopPropagation();
                  handleMouseEnter(task?._id);
                }}
              >
                {status === "TODO" && <CircleIcon color="#06719A" />}
                {status === "IN_PROGRESS" && <ClockIcon color="#FF7700" />}
                {status === "BLOCKED" && <CrossCircledIcon color="#EF4870" />}
                <span className="regularS white p-1">
                  {status === "TODO" && "To Do"}
                  {status === "IN_PROGRESS" && "In Progress"}
                  {status === "BLOCKED" && "Blocked"}
                </span>
              </div>
            )
          )}
          {/* <div
            className="flex items-center cursor-pointer mr-4" // Add cursor pointer for interactivity
            onClick={(e) => {
              e.stopPropagation();
              onClickComplete();
            }}
          >
            <CheckCircle />
            <span className={`regularS p-1 ${completed ? "white" : "green-300"}`}>
              {completed ? "Completed" : "Mark as complete"}
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );

  return (
    <ListComponent>
      {isDraggable ? (
        <Draggable draggableId={task._id.toString()} index={index}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={snapshot.isDragging ? "dragging" : ""}
            >
              {content}
            </div>
          )}
        </Draggable>
      ) : (
        content
      )}
    </ListComponent>
  );
};

export const EventListViewComponent = ({
  title,
  date,
  duration,
  description,
  location,
  item,
  onClick,
  handleEditTask,
  selectedProjectIndex,
  setData,
  onClickComplete = () => {},
  patchTask,
  setSelectedData,
  task,
  refetch,
}) => {
  const [isFirstRender, setIsFirstRender] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [editedDate, setEditedDate] = useState(date);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    const hasLoadedBeforeEvent = sessionStorage.getItem("hasLoadedBeforeEvent");
    if (!hasLoadedBeforeEvent) {
      setIsFirstRender(true);
      setTimeout(() => {
        sessionStorage.setItem("hasLoadedBeforeEvent", "true");
      }, 500);
    }
  }, [isFirstRender]);
  const EventComponent = isFirstRender ? AnimatedList : "div";

  const truncateText = (text, maxLength) => {
    if (!text) return "";
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  const handleEditComplete = (index, title, description, date) => {
    setIsEditingTitle(false);
    // const updatedTasks = { ...task };
    // updatedTasks.title = editedTitle;
    // // setData(updatedTasks);
    // patchTask(updatedTasks, {
    //   onSuccess(res) {
    //     notify("Task updated successfully", "success");
    //   },
    //   onError: (err) => {
    //     notify(err?.error?.message, "error");
    //   },
    // });
  };

  const handleEditDateComplete = (date) => {
    if (editedDate === date) {
      setShowDatePicker(false);
      return;
    } else {
      setShowDatePicker(false);
      // const updatedData = data?.map((item, i) => {
      //   // If the item has a taskLists array, add the new task to it
      //   if (i === selectedProjectIndex && item?.tasksList) {
      //     const updatedTasks = [...item?.tasksList];
      //     // console.log("updatedTasks", updatedTasks);

      //     updatedTasks[i].endDate = date;
      //     // updatedTasks[index].description = description;
      //     // updatedTasks[index].date = date;
      //   }
      //   return item;
      // });

      // setData(updatedData);
      // patchTask(updatedData[selectedProjectIndex]?.tasksList?.[0], {
      //   onSuccess(res) {
      //     // if (selectedViewType === "List View") {
      //     //   setJustUpdated(true);
      //     // }
      //     // setIsModalOpen(false);
      //     // refetchDashboardData();
      //     notify("Task updated successfully", "success");
      //     refetch();
      //   },
      //   onError: (err) => {
      //     notify(err?.error?.message, "error");
      //   },
      // });
    }
    // handleEditTask();
    // Add any additional logic needed for successful edit here
  };
  return (
    <EventComponent>
      <div
        className="list-view-wrapper p-8 m-8 cursor-pointer"
        onClick={(e) => {
          if (!isEditingTitle) {
            onClick(e);
          }
        }}
      >
        <div className="flex-row justify-space-between">
          <div className="flex justify-between items-center">
            <div
              className="w-full relative"
              onMouseEnter={() => setShowEditIcon(true)}
              onMouseLeave={() => setShowEditIcon(false)}
            >
              {isEditingTitle ? (
                <input
                  ref={inputRef}
                  type="text"
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                  onBlur={handleEditComplete}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleEditComplete();
                    }
                  }}
                  className="text-lg font-semibold bg-transparent text-white border-none border-white focus:outline-none p-0"
                />
              ) : (
                <span className="text-white text-lg font-semibold">
                  {title}
                </span>
              )}
              {showEditIcon && !isEditingTitle && (
                <Pencil1Icon
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsEditingTitle(true);
                    setSelectedData(task);

                    // setSelectedData(data)
                  }}
                  className="absolute right-0 top-1/2 transform -translate-y-1/2 cursor-pointer text-white"
                />
              )}
            </div>
          </div>
          {/* <span className="regularS white">{title}</span> */}
          <div className="flex-row align-center w-40">
            <div className="flex-row align-center">
              <CalendarIcon />{" "}
              <span className="regularS p-4 red">
                {item?.isGoogleEvent
                  ? moment(item?.calendarData?.start?.dateTime).format(
                      "MMM D YYYY"
                    )
                  : moment(date).format("MMM D YYYY")}
              </span>
            </div>
            {showDatePicker && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="absolute top-full mt-2 z-10 p-2 rounded-md shadow-lg"
              >
                <DatePicker
                  className="bg-transparent"
                  selected={new Date(date)}
                  value={
                    item?.isGoogleEvent
                      ? item?.calendarData?.start?.dateTime
                      : date
                  }
                  onChange={(newDate) => {
                    setSelectedData({ ...task, endDate: newDate });
                    setEditedDate(newDate);
                    handleEditDateComplete(newDate);
                    setShowDatePicker(false);
                  }}

                  // inline
                />
              </div>
            )}
            {location && (
              <div className="flex-row align-center">
                <LocationIcon />
                <span className="regularS p-4 white">
                  {truncateText(location, 20)}
                </span>
              </div>
            )}
            <span>{duration}</span>
          </div>
        </div>
        <div>
          <span className="white">{truncateText(description, 49)}</span>
        </div>
      </div>
    </EventComponent>
  );
};
